import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import setSelectedOptions from '@redux/thunks/collections/setSelectedOptions'
import Button from 'components/common/Button'
import { ModalFooter } from 'components/common/Modal/ConfirmationModal'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { modalKeys, modalTrackingKeys } from 'config/modals'
import { MODAL_MODES } from 'helpers/collections'
import { useModal } from 'hooks/application'

import styles from './Warning.module.scss'

const Warning = ({ mode }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { closeModal } = useModal(modalKeys.SWAP_COLLECTION)

  const handleRejectClick = () => {
    closeModal()
  }

  const handleConfirmClick = () => {
    dispatch(
      setSelectedOptions({
        action: mode,
        modalMode: MODAL_MODES.OPTIONS,
      }),
    )
  }

  return (
    <>
      <div>
        <h2>{t('your-collections.page.show.swap-modal.confirmation-modal.title')}</h2>
        <strong className={styles.warning}>
          {t('your-collections.page.show.swap-modal.confirmation-modal.warning')}
        </strong>
        <p>
          <SetInnerHtmlSafe>
            {t('your-collections.page.show.swap-modal.confirmation-modal.content.text-1')}
          </SetInnerHtmlSafe>
        </p>
        <p>
          <SetInnerHtmlSafe>
            {t('your-collections.page.show.swap-modal.confirmation-modal.content.p_2.text', {
              boldText1: `<b>${t(
                'your-collections.page.show.swap-modal.confirmation-modal.content.p_2.bold_text_1',
              )}</b>`,
              boldText2: `<b>${t(
                'your-collections.page.show.swap-modal.confirmation-modal.content.p_2.bold_text_2',
              )}</b>`,
            })}
          </SetInnerHtmlSafe>
        </p>
        <p>
          <SetInnerHtmlSafe>
            {t('your-collections.page.show.swap-modal.confirmation-modal.content.p_3.text', {
              underlinedText: `<u>${t(
                'your-collections.page.show.swap-modal.confirmation-modal.content.p_3.underlined_text',
              )}</u>`,
            })}
          </SetInnerHtmlSafe>
        </p>
      </div>
      <ModalFooter>
        <Button
          data-tracking={modalTrackingKeys.reject}
          onClick={handleRejectClick}
          secondary
        >
          {t('your-collections.page.show.swap-modal.confirmation-modal.buttons.cancel')}
        </Button>
        <Button
          data-tracking={modalTrackingKeys.confirm}
          onClick={handleConfirmClick}
        >
          {t('your-collections.page.show.swap-modal.confirmation-modal.buttons.confirm')}
        </Button>
      </ModalFooter>
    </>
  )
}

Warning.propTypes = {
  mode: PropTypes.string.isRequired,
}

export default Warning
