import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { memo } from 'react'

import { useIsMobile } from 'hooks/application'

const variants = {
  enter: {
    opacity: 0,
    scale: 0.85,
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    zIndex: 0,
    opacity: 0,
    scale: 0.85,
    transition: {
      duration: 0.1,
    },
  },
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

const AnimatedWizard = ({ children, step, paginate }) => {
  const isMobile = useIsMobile()

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="induction-quiz-question"
        key={step}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 200, damping: 30 },
          opacity: { duration: 0.3 },
        }}
        drag={isMobile === true ? 'x' : false}
        dragDirectionLock
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        onDragEnd={(_e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x)

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1)
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1)
          }
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

AnimatedWizard.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
}

export default memo(AnimatedWizard)
