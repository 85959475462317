import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './Stack.module.scss';
const Stack = ({ className, children, gap = '1rem' }) => {
    const style = { '--stack-vertical-gap': gap };
    return (_jsx("div", { className: clsx(styles.root, className), style: style, children: children }));
};
Stack.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    gap: PropTypes.string,
};
export default Stack;
