import PropTypes from 'prop-types'
import { memo } from 'react'

import { modalKeys } from 'config/modals'
import { COLLECTION_ACTIONS } from 'helpers/collections'
import { useModal } from 'hooks/application'

import ActionsModal from './ActionsModal'

const UnassignModal = ({ collectionId }) => {
  const { isOpen } = useModal(modalKeys.UNASSIGN_COLLECTION)

  if (isOpen === false) {
    return null
  }

  return (
    <ActionsModal
      collectionId={collectionId}
      mode={COLLECTION_ACTIONS.UNASSIGN}
    />
  )
}

UnassignModal.propTypes = {
  collectionId: PropTypes.number.isRequired,
}

export default memo(UnassignModal)
