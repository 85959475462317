import { isProduction } from 'helpers/environment';
const HOTJAR_ID_PRODUCTION = 3312193;
const HOTJAR_ID_STAGING = 3197034;
const HOTJAR_VERSION = 6;
const hotJarId = () => {
    if (isProduction === true) {
        return HOTJAR_ID_PRODUCTION;
    }
    return HOTJAR_ID_STAGING;
};
const HOTJAR_ID = hotJarId();
export { HOTJAR_ID, HOTJAR_VERSION };
