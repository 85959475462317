import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import { DASHBOARD_URL, JOIN_INTRO_URL, LOGIN_URL, LOW_RATING_ERROR_URL } from 'config/routes'
import { useAuthenticatedUser, useIsUserAuthenticated } from 'hooks/users'

const RequireFoodHeroRegistrationAccess = ({ children }) => {
  const { isFoodHero, hasLowRating, isUserLoaded } = useAuthenticatedUser({ shouldFetchUser: true })
  const isUserAuthenticated = useIsUserAuthenticated()
  const { isUserVerified } = useAuthenticatedUser()

  if (isUserAuthenticated === false) {
    window.location.replace(LOGIN_URL)
    return null
  }

  if (isUserLoaded === false) {
    return null
  }

  if (isFoodHero === true) {
    return (
      <Navigate
        to={DASHBOARD_URL}
        replace
      />
    )
  }

  if (hasLowRating === true) {
    return (
      <Navigate
        to={LOW_RATING_ERROR_URL}
        replace
      />
    )
  }

  if (isUserVerified === false) {
    return (
      <Navigate
        to={JOIN_INTRO_URL}
        replace
      />
    )
  }

  return children
}

RequireFoodHeroRegistrationAccess.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RequireFoodHeroRegistrationAccess
