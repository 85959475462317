import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FieldError from 'components/common/FieldError';
import styles from './TextField.module.scss';
const TextField = ({ name, rules, defaultValue, labelText, customErrorMessage, type = 'text', onChange, }) => {
    const { control } = useFormContext();
    const isRequired = rules?.required === true;
    return (_jsx(Controller, { control: control, name: name, rules: rules, render: ({ field: { onChange: onChangeNative, name, ref, onBlur, value }, fieldState }) => {
            const handleChange = (event) => {
                if (onChange !== undefined) {
                    onChange();
                }
                onChangeNative(event.target.value);
            };
            return (_jsxs(_Fragment, { children: [_jsxs("label", { className: styles.textField, htmlFor: name, children: [_jsx("input", { ref: ref, id: name, onChange: handleChange, onBlur: onBlur, type: type, value: value ?? defaultValue ?? '' }), _jsx("span", { className: clsx(isRequired && styles.isRequired), children: labelText })] }), fieldState.error && (_jsx(FieldError, { error: {
                            ...fieldState.error,
                            message: customErrorMessage,
                        } }))] }));
        } }));
};
TextField.propTypes = {
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    defaultValue: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    customErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    onChange: PropTypes.func,
};
export default TextField;
