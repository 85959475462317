import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import { DASHBOARD_URL, JOIN_INTRO_URL } from 'config/routes'
import { useAuthenticatedUser, useCurrentUserId } from 'hooks/users'

const LandingPageRedirects = ({ children }) => {
  const userId = useCurrentUserId()
  const { isFoodHero } = useAuthenticatedUser()

  if (userId) {
    if (isFoodHero === true) {
      return <Navigate to={DASHBOARD_URL} />
    } else if (isFoodHero === false) {
      return <Navigate to={JOIN_INTRO_URL} />
    }
    return null
  }
  return children
}

LandingPageRedirects.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LandingPageRedirects
