import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPictureHeaderConfig } from 'config/pictureHeader';
import styles from './PictureHeader.module.scss';
const PictureHeader = ({ variant }) => {
    const { t } = useTranslation();
    const pictureHeaderConfig = useMemo(() => {
        return getPictureHeaderConfig(t);
    }, []);
    return (_jsxs("picture", { children: [_jsx("source", { media: "(max-width: 400px)", type: "image/webp", srcSet: pictureHeaderConfig[variant].mobileWebp, className: styles[variant] }), _jsx("source", { media: "(max-width: 400px)", srcSet: pictureHeaderConfig[variant].mobile }), _jsx("source", { media: "(min-width: 400px)", type: "image/webp", srcSet: pictureHeaderConfig[variant].desktopWebp, className: styles[variant] }), _jsx("source", { media: "(min-width: 400px)", srcSet: pictureHeaderConfig[variant].desktop, className: styles[variant] }), _jsx("img", { className: clsx(styles.image, styles[variant]), alt: pictureHeaderConfig[variant].altText, src: pictureHeaderConfig[variant].mobile })] }));
};
PictureHeader.propTypes = {
    variant: PropTypes.string.isRequired,
};
export default PictureHeader;
