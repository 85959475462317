import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getCollectionActionDetails } from 'helpers/collections'

import StepContent from './StepContent'
import StepHeader from './StepHeader'

const Step = ({ step, mode }) => {
  const { t } = useTranslation()
  const activeStep = useSelector((state) => state.collections[mode].step)

  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  })
  const isStepComplete = () =>
    COLLECTION_ACTIONS_DETAILS[mode].steps[activeStep].position >
    COLLECTION_ACTIONS_DETAILS[mode].steps[step].position

  return (
    <div className={`step${isStepComplete() ? ' complete' : ''}`}>
      <StepHeader
        step={step}
        mode={mode}
      />
      <StepContent
        step={step}
        mode={mode}
      />
    </div>
  )
}

Step.propTypes = {
  step: PropTypes.string,
  mode: PropTypes.string,
}

export default Step
