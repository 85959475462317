import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { DASHBOARD_URL, JOIN_INTRO_URL } from 'config/routes'
import { useAuthenticatedUser, useCurrentUserId } from 'hooks/users'

const RequireNoAuthentication = ({ children }) => {
  const location = useLocation()
  const userId = useCurrentUserId()
  const { isFoodHero } = useAuthenticatedUser()

  if (userId) {
    if (isFoodHero === true) {
      const { pathname: origin } = location.state?.from || {}
      const redirectPath = origin || DASHBOARD_URL
      window.location.replace(redirectPath)
    } else if (isFoodHero === false) {
      window.location.replace(JOIN_INTRO_URL)
    } else {
      return children
    }
  }

  return children
}

RequireNoAuthentication.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RequireNoAuthentication
