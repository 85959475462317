import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAutoFixHigh } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import fetchMyInductions from '@redux/thunks/inductions/fetchMyInductions'
import InductionsList from 'components/Inductions/InductionsList'
import CollapsibleAlert from 'components/common/CollapsibleAlert'
import InfoItem, { infoItemTypes } from 'components/common/InfoItem'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import { Stack } from 'components/layout/primitives'
import { MESSAGE } from 'config/alert'
import { FETCHING_STATUSES } from 'config/api'
import { getCompletedInductions, getNonCompletedInductions } from 'helpers/inductions'
import LemonIcon from 'images/Lemon.svg'

import styles from './InductionsListScene.module.scss'

const { IDLE, LOADING } = FETCHING_STATUSES

const { EMPTY } = infoItemTypes

const InductionsListScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const inductions = useSelector((state) => state.inductions.items)

  const completedInductions = useMemo(() => getCompletedInductions(inductions), [inductions])
  const nonCompletedInductions = useMemo(() => getNonCompletedInductions(inductions), [inductions])

  const noInductions = completedInductions.length === 0 && nonCompletedInductions.length === 0

  useEffect(() => {
    dispatch(fetchMyInductions())
  }, [])

  const inductionsFetchingStatus = useSelector((state) => state.inductions.inductionsFetchingStatus)

  return (
    <div id="inductions-scene">
      <SceneHeader title={t('inductions.page.index.new-title')} />
      <div className={styles.collapsibleAlert}>
        <CollapsibleAlert
          title={t('inductions.scene.alert.title')}
          description={t('inductions.scene.alert.description')}
          variant={MESSAGE}
          icon={<MdAutoFixHigh />}
        >
          {t('inductions.scene.alert.extended-description')}
        </CollapsibleAlert>
      </div>
      {inductionsFetchingStatus === IDLE || inductionsFetchingStatus === LOADING ? (
        <LoadingSpinner containerHeight="400px" />
      ) : noInductions === true ? (
        <InfoItem
          type={EMPTY}
          title={t('inductions.page.index.empty-title')}
          description1={t('inductions.page.index.empty-text')}
          icon={LemonIcon}
        />
      ) : (
        <Stack gutter="1rem">
          <InductionsList
            title={t('inductions.page.index.to-do-title')}
            inductions={nonCompletedInductions}
          />
          <InductionsList
            title={t('inductions.page.index.completed-title')}
            inductions={completedInductions}
          />
        </Stack>
      )}
    </div>
  )
}

export default InductionsListScene
