import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import fetchBasket from '@redux/thunks/baskets/fetchBasket'
import Debug from 'helpers/debug'

import Article from './Article'

const debug = Debug('components:basket:item')

const Item = ({ basketId }) => {
  const dispatch = useDispatch()
  const basket = useSelector((state) => state?.baskets?.items[basketId])
  const basketArticleIds = basket?.basketArticleIds
  const requesterId = basket?.requesterId

  useEffect(() => {
    if (undefined === basketArticleIds || undefined === requesterId) {
      dispatch(fetchBasket(basketId)).catch(debug)
    }
  }, [])

  return (
    <>
      {basketArticleIds &&
        basketArticleIds.map((id) => (
          <Article
            key={id}
            basketArticleId={id}
          />
        ))}
    </>
  )
}

Item.propTypes = {
  basketId: PropTypes.number.isRequired,
}

export default Item
