import PropTypes from 'prop-types'
import { memo } from 'react'

import Item from 'components/Baskets/List/Content/Item'

const Content = ({ section }) => {
  return <Item basketId={section.id} />
}

Content.propTypes = {
  section: PropTypes.object,
}

export default memo(Content)
