import clsx from 'clsx'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdOutlineArrowForward } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences'
import updateUserPreferences from '@redux/thunks/preferences/updateUserPreferences'
import Alert from 'components/common/Alert/Alert'
import Button from 'components/common/Button'
import LoadingSpinner from 'components/common/LoadingSpinner'
import RadioButtonGroup from 'components/common/RadioButtonGroup'
import SceneHeader from 'components/common/SceneHeader'
import { Grid, Stack } from 'components/layout/primitives'
import { ERROR, STATUS } from 'config/alert'
import { FETCHING_STATUSES } from 'config/api'
import Debug from 'helpers/debug'
import {
  filterNotificationPreferences,
  getCardIcon,
  getCardIconClass,
  getCardTitles,
  getOptions,
} from 'helpers/notificationPreferences'
import { useIsMobile } from 'hooks/application'

import styles from './NotificationPreferencesScene.module.scss'

const { LOADING, IDLE, SUCCESS, FAILED } = FETCHING_STATUSES
const debug = Debug('scenes:notification-preferences-scene')

const NotificationPreferencesScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const methods = useForm({ mode: 'onChange' })
  const { getValues } = methods
  const { userPreferences, preferencesFetchingStatus, preferencesUpdatingStatus } = useSelector(
    (state) => state.preferences,
  )
  const alertShown = preferencesUpdatingStatus === SUCCESS || preferencesUpdatingStatus === FAILED

  const cardTitles = useMemo(() => {
    return getCardTitles(t)
  })

  const options = useMemo(() => {
    return getOptions(t)
  })
  const onSubmit = async () => {
    const preferences = getValues()
    if (preferences !== undefined) {
      dispatch(updateUserPreferences(preferences)).catch(debug)
    }
  }

  const notificationPreferences = useMemo(() => {
    const userNotificationPreferences = filterNotificationPreferences(userPreferences)

    return Object.keys(cardTitles).map((notificationType) => {
      const userPreference = userNotificationPreferences.find(
        (preference) => preference.name === notificationType,
      )
      if (userPreference !== undefined) {
        return userPreference
      }
      return { name: notificationType }
    })
  }, [userPreferences])

  useEffect(() => {
    dispatch(fetchUserPreferences()).catch(debug)
  }, [])

  if (preferencesFetchingStatus === LOADING || preferencesFetchingStatus === IDLE) {
    return <LoadingSpinner containerHeight="400px" />
  }

  return (
    <div className={styles.notificationPreferencesScene}>
      <div className={styles.header}>
        <SceneHeader
          title={t('notification-settings.title')}
          description={t('notification-settings.description')}
        />
      </div>

      <FormProvider {...methods}>
        <Stack gutter="1rem">
          <Grid
            columns={isMobile === true ? 1 : 3}
            gap="1rem"
          >
            {notificationPreferences.map(({ name, value }) => {
              return (
                <div
                  key={name}
                  className={clsx(styles.card, 'card')}
                >
                  <div className={clsx(styles.cardContent, 'card-content')}>
                    <div className={styles.cardHeader}>
                      <span className={clsx(styles.icon, styles[getCardIconClass(name)])}>
                        {getCardIcon(name)()}
                      </span>
                      <h3 className={styles.title}>{cardTitles[name]}</h3>
                    </div>
                    <div className={styles.radioButtonWrapper}>
                      <RadioButtonGroup
                        name={name}
                        selectedValue={value}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </Grid>
        </Stack>
        <div className={styles.settingsButtonWrapper}>
          <div
            className={clsx(styles.alertWrapper, {
              [styles.alertVisible]: alertShown === true,
            })}
          >
            {preferencesUpdatingStatus === SUCCESS && (
              <Alert variant={STATUS}>
                <strong>{t('common.alerts.status')} </strong>
                {t('notification-settings.update.success')}
              </Alert>
            )}
            {preferencesUpdatingStatus === FAILED && (
              <Alert variant={ERROR}>
                <strong>{t('common.alerts.error')} </strong>
                {t('notification-settings.update.error')}
              </Alert>
            )}
          </div>
          <Button
            className={styles.saveButton}
            onClick={onSubmit}
          >
            {t('notification-settings.update.button')}
          </Button>
          <a
            href={t('notification-settings.info-link.url')}
            className={styles.slotTypesLink}
            target="_blank"
            rel="noreferrer"
          >
            {t('notification-settings.info-link.text')}
            <MdOutlineArrowForward />
          </a>
        </div>
      </FormProvider>
    </div>
  )
}

export default NotificationPreferencesScene
