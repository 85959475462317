// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableCollectionInformation-module--header--V2_jg{margin-bottom:1rem;font-size:1.125rem}.AvailableCollectionInformation-module--foodConfirmedLabel--NoVyA{margin-bottom:1rem;margin-top:1rem;border-radius:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "AvailableCollectionInformation-module--header--V2_jg",
	"foodConfirmedLabel": "AvailableCollectionInformation-module--foodConfirmedLabel--NoVyA"
};
export default ___CSS_LOADER_EXPORT___;
