import { AUTHENTICATE_USER_ERROR, AUTHENTICATE_USER_SUCCESS, USER_FETCH_SUCCESS, } from '@redux/actionTypes';
import { LOGIN_TYPES } from 'config/loginTypes';
import { DASHBOARD_URL, JOIN_INTRO_URL } from 'config/routes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
import { isUserFoodHero } from 'helpers/user';
const debug = Debug('redux:thunks:users:login-password');
const { PASSWORD } = LOGIN_TYPES;
export default (email, password, rememberMe, originPathname) => {
    return async (dispatch) => {
        const onFail = (error) => {
            const reason = loggableError(error);
            debug(`Failed authenticating user`, reason);
            dispatch({
                type: AUTHENTICATE_USER_ERROR,
                payload: {
                    errorType: PASSWORD,
                },
                meta: {
                    analytics: {
                        eventName: AUTHENTICATE_USER_ERROR,
                        eventPayload: {
                            type: PASSWORD,
                        },
                    },
                },
            });
        };
        try {
            const response = await axios.post(`/api/v1/sessions`, {
                email,
                password,
                remember_me: rememberMe,
            });
            const { user } = response?.data || {};
            const { id } = user;
            window.userId = id;
            dispatch({
                type: AUTHENTICATE_USER_SUCCESS,
                payload: {
                    id,
                },
                meta: {
                    analytics: {
                        eventName: AUTHENTICATE_USER_SUCCESS,
                        eventPayload: {
                            type: PASSWORD,
                            userId: id
                        },
                    },
                },
            });
            dispatch({
                type: USER_FETCH_SUCCESS,
                payload: {
                    userId: id,
                    name: user.first_name,
                    email: user.email,
                    rating: user.rating,
                    location: user.location,
                    avatar: user.current_avatar,
                },
            });
            if (isUserFoodHero(user.roles) === true) {
                const redirectPath = originPathname ?? DASHBOARD_URL;
                window.location.assign(redirectPath);
            }
            else {
                window.location.assign(JOIN_INTRO_URL);
            }
        }
        catch (error) {
            onFail(error);
        }
    };
};
