import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation'
import { getSignedStaticMapUrl } from 'helpers/map'
import { useIsMobile } from 'hooks/application'

import styles from './DisplayedCollection.module.scss'

const DisplayedCollection = ({ collection }) => {
  const isMobile = useIsMobile()
  const [mapUrl, setMapUrl] = useState()

  useEffect(() => {
    const getMapUrl = async () => {
      const url = await getSignedStaticMapUrl({
        latitude: collection.storeLocation.latitude,
        longitude: collection.storeLocation.longitude,
        width: 520,
        height: 157,
      })
      setMapUrl(url)
    }
    if (isMobile === false) {
      getMapUrl()
    }
  }, [isMobile, collection.storeLocation])

  return (
    <div className={clsx('card', styles.displayedCollection)}>
      <div className="card-content">
        <h3 className={styles.cardHeader}>
          <span>{collection.businessName}</span>
          {collection.storeName !== undefined && (
            <>
              <span> | </span>
              <span>{collection.storeName}</span>
            </>
          )}
        </h3>
        <AvailableCollectionInformation
          type={collection.type}
          storeLocation={collection.storeLocation}
          homeLocation={collection.homeLocation}
          frequency={collection.frequency}
          nextCollection={collection.nextCollection}
          storeAddress={collection.storeAddress}
          businessName={collection.businessName}
          className={styles.availebleCollectionInformation}
        />
        {isMobile === false && mapUrl !== undefined && (
          <img
            className={styles.mapImage}
            width="520"
            height="157"
            alt=""
            src={mapUrl}
            data-testid="map-image"
          />
        )}
      </div>
    </div>
  )
}

const collectionPropType = PropTypes.shape({
  storeLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  homeLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  frequency: PropTypes.string.isRequired,
  nextCollection: PropTypes.string.isRequired,
  storeAddress: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    postCode: PropTypes.string,
  }).isRequired,
  businessName: PropTypes.string.isRequired,
  storeName: PropTypes.string,
  type: PropTypes.string.isRequired,
})

DisplayedCollection.propTypes = {
  collection: collectionPropType,
}

export { collectionPropType }

export default DisplayedCollection
