import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import fetchUser from '@redux/thunks/users/fetchUser'
import UserAvatar from 'components/common/UserAvatar'
import Debug from 'helpers/debug'

const debug = Debug('components:basket:requester:index')

const Requester = ({ basketId }) => {
  const userId = useSelector((state) => state?.baskets.items[basketId]?.requesterId)
  const user = useSelector((state) => state?.users.items[userId])
  const name = user?.name

  const dispatch = useDispatch()

  useEffect(() => {
    if (undefined === name) {
      dispatch(fetchUser(userId)).catch(debug)
    }
  }, [])

  return (
    <div className="icon-wrapper">
      <UserAvatar userId={userId} />
      <h3>{name}</h3>
    </div>
  )
}

Requester.propTypes = {
  basketId: PropTypes.number.isRequired,
}

export default Requester
