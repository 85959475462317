import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { convertSecondsToMinutes } from 'helpers/time'
import VideoDurationIcon from 'images/VideoDurationIcon.svg'

const VideoDuration = ({ durationInSeconds }) => {
  const { t } = useTranslation()
  const durationInMinutes = useMemo(() => {
    return convertSecondsToMinutes(durationInSeconds)
  }, [durationInSeconds])

  if (typeof durationInSeconds !== 'number') {
    return null
  }

  return (
    <div className="video-duration">
      <img
        src={VideoDurationIcon}
        alt=""
      />
      <span className="video-duration-text">
        {t('inductions.page.show.video_duration', { count: durationInMinutes })}
      </span>
    </div>
  )
}

VideoDuration.propTypes = {
  durationInSeconds: PropTypes.number.isRequired,
}

export default VideoDuration
