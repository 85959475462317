import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineContactSupport } from 'react-icons/md'

import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'

const AboutInduction = ({ handleStartQuizClick, questionCount }) => {
  const { t } = useTranslation()
  const [showStartQuizButton, setShowStartQuizButton] = useState(false)

  return (
    <div className="card about-induction-card">
      <h2>{t('inductions.page.show.about_this_induction.title')}</h2>
      <div className="about-induction-question-number">
        <MdOutlineContactSupport
          className="icon"
          data-testid="contact-support-icon"
        />
        <span>
          {t('inductions.page.show.about_this_induction.question_count', {
            count: questionCount,
          })}
        </span>
      </div>
      <p>{t('inductions.page.show.about_this_induction.description')}</p>
      <Checkbox
        checked={showStartQuizButton}
        id="confirm-watched-video-checkbox"
        label={t('inductions.page.show.about_this_induction.checkbox_label')}
        onChange={() => setShowStartQuizButton((value) => !value)}
      />

      {showStartQuizButton === true && (
        <Button
          className="about-induction-start-quiz"
          onClick={handleStartQuizClick}
        >
          {t('inductions.page.show.button-start')}
        </Button>
      )}
    </div>
  )
}

AboutInduction.propTypes = {
  handleStartQuizClick: PropTypes.func.isRequired,
  questionCount: PropTypes.number.isRequired,
}

export default AboutInduction
