import { useSelector } from 'react-redux'

import BasketConfirmationModal from 'components/Baskets/BasketConfirmationModal'
import { modalKeys } from 'config/modals'

const { CLOSE_BASKET_CONFIRMATION } = modalKeys

const ApplicationModals = () => {
  const openedModals = useSelector((state) => state.application.openedModals)

  if (!openedModals?.length === 0) {
    return null
  }

  const isModalVisible = (key) => {
    return openedModals.filter((modal) => modal.key === key).length === 1
  }

  return <>{isModalVisible(CLOSE_BASKET_CONFIRMATION) && <BasketConfirmationModal />}</>
}

export default ApplicationModals
