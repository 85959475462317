import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@redux/hooks';
import fetchCollection from '@redux/thunks/collections/fetchCollection';
import SuccessModal from 'components/common/Modal/SuccessModal';
import { modalKeys } from 'config/modals';
import { MY_COLLECTIONS_URL } from 'config/routes';
import { formatDateToDayAndTime24 } from 'helpers/time';
import { useModal } from 'hooks/application';
import { useCollection } from 'hooks/collections';
const ClaimCollectionSuccessModal = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { closeModal, data: { collectionId }, } = useModal(modalKeys.CLAIM_COLLECTION_SUCCESS);
    const navigate = useNavigate();
    const { data: collection } = useCollection({ collectionId });
    const handleCloseModal = () => {
        closeModal();
        const myCollectionDetailsUrl = `${MY_COLLECTIONS_URL}/${collectionId}?new=true`;
        navigate(myCollectionDetailsUrl);
    };
    useEffect(() => {
        if (collectionId !== undefined && collection?.nextCollection === undefined) {
            dispatch(fetchCollection(collectionId));
        }
    }, [collectionId, collection.nextCollection]);
    return (_jsxs(SuccessModal, { onClose: handleCloseModal, modalKey: modalKeys.CLAIM_COLLECTION_SUCCESS, children: [_jsx("h2", { children: t('collection-slots.claim.success_modal.title') }), _jsx("p", { children: t('collection-slots.claim.success_modal.description') }), _jsx("strong", { children: t('collection-slots.claim.success_modal.collection_info', {
                    time: formatDateToDayAndTime24(collection?.nextCollection),
                    businessName: collection?.business?.name,
                    businessStreetName: collection?.store?.address?.addressLine1,
                }) })] }));
};
export default ClaimCollectionSuccessModal;
