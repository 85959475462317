import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import fetchUser from '@redux/thunks/users/fetchUser'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { MINIMUM_FOOD_HERO_RATING } from 'config/user'
import Debug from 'helpers/debug'
import { getCurrentUserId } from 'helpers/user'

const debug = Debug('scenes:access-error-scene')

const AccessErrorScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const currentUserId = getCurrentUserId()

  const rating = useSelector((state) => state.users.items[currentUserId]?.rating?.rating)
  const ratingOutOf5 = rating / 2

  const currentRatingText = t('register.food-waste-hero.rating-needs-updating.my_current_rating', {
    current_rating: `<b>${ratingOutOf5}</b>`,
  })
  const improveRatingText = t('register.food-waste-hero.rating-needs-updating.improve_rating', {
    minimum_rating: Number(MINIMUM_FOOD_HERO_RATING) / 2,
  })

  useEffect(() => {
    dispatch(fetchUser(currentUserId)).catch(debug)
  }, [currentUserId])

  return (
    <main id="access-error-scene">
      <div className="container">
        <h1>{t('register.food-waste-hero.rating-needs-updating.title')}</h1>
        <p>
          {t('register.food-waste-hero.rating-needs-updating.description')}
          <span className="left-padded">
            <a href="https://help.olioex.com/article/134-ratings-explained">
              {t('register.food-waste-hero.rating-needs-updating.link-text')}
            </a>
          </span>
        </p>
        {isNaN(rating) === false && (
          <p>
            <SetInnerHtmlSafe>{currentRatingText}</SetInnerHtmlSafe>
          </p>
        )}
        <p>
          <strong>
            <SetInnerHtmlSafe>{improveRatingText}</SetInnerHtmlSafe>
          </strong>
        </p>
      </div>
    </main>
  )
}

export default AccessErrorScene
