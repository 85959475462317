import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MdOutlineCheckCircle } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/common/Button'
import * as routes from 'config/routes'
import { trackEvent } from 'services/Analytics'

const InductionCard = ({ title, description, isComplete, inductionId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleClick = () => {
    trackEvent('INDUCTION_START', {
      induction_name: title,
      induction_id: inductionId,
      refresh: isComplete,
    })
    if (isComplete) {
      trackEvent('REVIEW_INDUCTION', { induction_name: title, induction_id: inductionId })
    }

    navigate(`${routes.INDUCTIONS_URL}/${inductionId}`, { state: { origin: pathname } })
  }

  return (
    <div className="card induction-card">
      <div className="card-content">
        <div>
          <h3 className="induction-title">{title}</h3>
          <p className="induction-description">{description}</p>
        </div>
        <span>
          <div className="induction-actions">
            <Button
              onClick={handleClick}
              secondary
            >
              {isComplete === true
                ? t('inductions.induction.action.view')
                : t('inductions.induction.action.start')}
            </Button>

            {isComplete === true && (
              <div className="is-complete">
                <MdOutlineCheckCircle />
                <span className="completed-text">{t('inductions.induction.action.completed')}</span>
              </div>
            )}
          </div>
        </span>
      </div>
    </div>
  )
}

InductionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  inductionId: PropTypes.number.isRequired,
}

export default InductionCard
