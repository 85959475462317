import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import styles from './InfoBannerWithButton.module.scss';
const InfoBannerWithButton = ({ title, buttonText, onButtonClick }) => {
    return (_jsx("div", { className: clsx(styles.infoBannerWithButton, 'card'), children: _jsxs("div", { className: clsx(styles.cardContent), children: [_jsx("p", { children: title }), _jsx(Button, { secondary: true, onClick: onButtonClick, children: buttonText })] }) }));
};
InfoBannerWithButton.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
};
export default InfoBannerWithButton;
