import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './RadioButtonGroup.module.scss';
const RadioButtonGroup = ({ name, selectedValue, options, alignLabelLeft = true }) => {
    const { control } = useFormContext();
    return (_jsx("section", { children: options.map(({ label, value }) => {
            return (_jsx("div", { children: _jsx(Controller, { control: control, defaultValue: selectedValue, name: name, render: ({ field: { onChange, onBlur } }) => {
                        return (_jsxs("label", { className: clsx({
                                [styles.radioButtonLabelLeft]: alignLabelLeft === true,
                            }), children: [_jsx("input", { type: "radio", className: "with-gap", name: name, value: value, defaultChecked: value === selectedValue, onChange: onChange, onBlur: onBlur }), _jsx("span", { children: label })] }));
                    } }) }, value));
        }) }));
};
RadioButtonGroup.propTypes = {
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    alignLabelLeft: PropTypes.bool,
};
export default memo(RadioButtonGroup);
