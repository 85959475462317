import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Note = ({ title, note, className }) => {
    return (_jsxs("div", { className: className, children: [_jsx("h3", { className: "note-title", children: title }), _jsx("span", { className: "note-text", children: note })] }));
};
Note.propTypes = {
    title: PropTypes.string.isRequired,
    note: PropTypes.node.isRequired,
    className: PropTypes.string,
};
export default Note;
