import { jsx as _jsx } from "react/jsx-runtime";
import { convertDistance, getDistance } from 'geolib';
import { useTranslation } from 'react-i18next';
export const MAP_URL_PREFIX = 'https://www.google.com/maps/search/?api=1&query=';
const CollectionDistance = ({ units, baseLocation, destinationLocation, }) => {
    const { t } = useTranslation();
    if (!baseLocation || !destinationLocation) {
        return null;
    }
    const MAPS_URL = `${MAP_URL_PREFIX}${destinationLocation.latitude},${destinationLocation.longitude}`;
    const distanceInMeters = getDistance(baseLocation, destinationLocation);
    const distanceInUserUnits = convertDistance(distanceInMeters, units).toFixed(1);
    return (_jsx("div", { className: "info", children: _jsx("div", { className: "collection-distance", children: _jsx("span", { dangerouslySetInnerHTML: {
                    __html: t('collection_slots.partials.slot.distance_from_your_location', {
                        distance: t('collection_slots.partials.slot.distance', {
                            quantity: distanceInUserUnits,
                            units: t(`common.units-short.${units}`),
                        }),
                        mapUrl: MAPS_URL,
                    }),
                } }) }) }));
};
export default CollectionDistance;
