import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MdOutlineLock } from 'react-icons/md'
import { Link } from 'react-router-dom'

import Button from 'components/common/Button'
import { REGISTER_URL } from 'config/routes'
import { trackEvent } from 'services/Analytics'

import styles from './RegisteringIsEasy.module.scss'

const RegisteringIsEasy = ({ isUserVerified }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.registeringIsEasy}>
      <h3>{t('join-intro-page.registering-is-easy.header')}</h3>
      {isUserVerified === true && (
        <>
          <p>{t('join-intro-page.registering-is-easy.verified-description')}</p>
          <Link to={REGISTER_URL}>
            <Button onClick={() => trackEvent('FWH_NAVIGATE_COLLECTIONS_TEASER_PROCEED')}>
              {t('join_intro_page.become_food_waste_volunteer')}
            </Button>
          </Link>
        </>
      )}
      {isUserVerified === false && (
        <>
          <p>{t('join-intro-page.registering-is-easy.unverified-description')}</p>
          <Button leftContent={<MdOutlineLock data-testid="lockIcon" />}>
            {t('join_intro_page.become_food_waste_volunteer')}
          </Button>
        </>
      )}
    </div>
  )
}

RegisteringIsEasy.propTypes = {
  isUserVerified: PropTypes.bool,
}

export default RegisteringIsEasy
