import { useTranslation } from 'react-i18next'

import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'

const TranslationsTestScene = () => {
  const { t } = useTranslation()
  return (
    <SetInnerHtmlSafe>
      {t('translations-test.text', {
        boldText: `<b>${t('translations-test.bold_text')}</b>`,
      })}
    </SetInnerHtmlSafe>
  )
}

export default TranslationsTestScene
