import { jsx as _jsx } from "react/jsx-runtime";
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
const SetInnerHtmlSafe = ({ children }) => {
    return _jsx("span", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(children) } });
};
SetInnerHtmlSafe.propTypes = {
    children: PropTypes.node.isRequired,
};
export default SetInnerHtmlSafe;
