import { isWideScreen } from 'helpers/display'

const expandingAnimationConfig = {
  initial: {
    height: 'auto',
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.4,
        ease: 'easeOut',
      },
      opacity: {
        duration: 0.25,
        delay: 0.4,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.5,
        ease: 'easeInOut',
      },
      opacity: {
        duration: 0,
      },
    },
  },
}

const buttonHeight = isWideScreen() ? '51px' : '40px'
const buttonExpandingAnimationConfig = {
  ...expandingAnimationConfig,
  initial: {
    height: buttonHeight,
  },
  animate: {
    height: buttonHeight,
  },
}

export { expandingAnimationConfig, buttonExpandingAnimationConfig }
