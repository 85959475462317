import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Note from 'components/common/Note'
import Rota from 'components/common/StoreRota/Rota'
import { useIsMobile } from 'hooks/application'

const CollectionDetails = ({ collectionId }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const selectedCollection = useSelector((state) => state.collections?.items[collectionId])
  const storeId = selectedCollection?.storeId
  const collectionNotes = selectedCollection?.notes

  const selectedStore = useSelector((state) => state.stores?.items[storeId])
  const businessId = selectedStore?.businessId
  const storeNotes = selectedStore?.notes

  const businessNotes = useSelector((state) => state.stores?.businesses[businessId])?.notes

  const hasNotes = Boolean(collectionNotes || storeNotes || businessNotes)

  return (
    <div className="collection-details">
      <div className={hasNotes === true ? 'notes-container' : 'notes-container no-border-mobile'}>
        {collectionNotes !== undefined && collectionNotes !== '' && (
          <Note
            title={t('your-collections.page.show.details.notes.collection-notes')}
            note={<span dangerouslySetInnerHTML={{ __html: collectionNotes }} />}
            className="note-wrapper"
          />
        )}

        {isMobile === true && (
          <Rota
            storeId={storeId}
            className="rota-wrapper"
            timezone={selectedStore?.timezone}
          />
        )}

        {storeNotes !== undefined && storeNotes !== '' && (
          <Note
            title={t('your-collections.page.show.details.notes.store-notes')}
            note={<span dangerouslySetInnerHTML={{ __html: storeNotes }} />}
            className="note-wrapper"
          />
        )}

        {businessNotes !== undefined && businessNotes !== '' && (
          <Note
            title={t('your-collections.page.show.details.notes.business-notes')}
            note={<span dangerouslySetInnerHTML={{ __html: businessNotes }} />}
            className="note-wrapper business-notes"
          />
        )}
      </div>

      {isMobile === false && (
        <Rota
          storeId={storeId}
          className="rota-wrapper"
          timezone={selectedStore?.timezone}
        />
      )}
    </div>
  )
}

CollectionDetails.propTypes = {
  collectionId: PropTypes.number,
}

export default CollectionDetails
