import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { buttonVariants } from 'config/buttons';
import styles from './Button.module.scss';
const Button = ({ children, className, testId, disabled = false, download = false, href, target, leftContent, onClick, secondary, buttonSize, type = 'button', }) => {
    if (href) {
        return (_jsxs("a", { href: href, role: "button", className: clsx(className, styles.button, { [styles.secondary]: secondary === true }, { 'btn-secondary': secondary === true }), onClick: onClick, "data-testid": testId, download: download, target: target, children: [leftContent && _jsx("span", { className: styles.leftContent, children: leftContent }), _jsx("span", { className: "label", children: children })] }));
    }
    return (_jsxs("button", { 
        // eslint-disable-next-line react/button-has-type
        type: type, className: clsx(className, styles[buttonSize], styles.button, { [styles.secondary]: secondary === true }, { 'btn-secondary': secondary === true }), onClick: onClick, "data-testid": testId, disabled: disabled, "aria-disabled": onClick === undefined || disabled === true ? 'true' : 'false', tabIndex: disabled ? -1 : 0, children: [leftContent && _jsx("span", { className: styles.leftContent, children: leftContent }), _jsx("span", { className: "label", children: children })] }));
};
Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    download: PropTypes.bool,
    href: PropTypes.string,
    target: PropTypes.string,
    leftContent: PropTypes.node,
    onClick: PropTypes.func,
    secondary: PropTypes.bool,
    testId: PropTypes.string,
    type: PropTypes.string,
    buttonSize: PropTypes.oneOf(buttonVariants),
};
export default Button;
