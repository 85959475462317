import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'

import SidebarContent from 'components/layout/Sidebar/Content'
import { TOGGLE_SIDEBAR as ANIMATION_OPTIONS } from 'helpers/animations'
import { useIsMobile } from 'hooks/application'

import styles from './Wrapper.module.scss'

const SidebarWrapper = () => {
  const isSidebarOpen = useSelector((state) => state.application.isSidebarOpen)
  const isMobile = useIsMobile()

  return (
    <motion.div
      initial={isMobile === true && 'mobileInitial'}
      animate={isSidebarOpen === false && isMobile === true ? 'close' : 'open'}
      variants={ANIMATION_OPTIONS.variants}
      transition={{ duration: ANIMATION_OPTIONS.duration }}
      className={styles.sidebarWrapper}
      data-testid="sidebarAnimated"
      role="navigation"
    >
      <SidebarContent />
    </motion.div>
  )
}

export default SidebarWrapper
