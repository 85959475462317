import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MdOutlineGroups } from 'react-icons/md'

import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe/SetInnerHtmlSafe'

import styles from './InnerCard.module.scss'

const NEARBY_USERS_LIMIT = 100

const InnerCard = ({ nearbyUsersCount }) => {
  const { t } = useTranslation()
  return (
    <article className={styles.card}>
      <div className={styles.headingSection}>
        <span className={styles.groupIcon}>
          <MdOutlineGroups />
        </span>
        <h3>{t('dashboard-scene.users-near-me.inner-card.heading')}</h3>
      </div>

      <section className={styles.textSection}>
        <p className={styles.totalHeading}>
          {t('dashboard-scene.users-near-me.inner-card.total-heading')}
        </p>

        {nearbyUsersCount < NEARBY_USERS_LIMIT ? (
          <p className={styles.totalText}>
            <SetInnerHtmlSafe>
              {nearbyUsersCount === 1
                ? t('dashboard-scene.users-near-me.inner-card.total-text.text-singular', {
                    nearbyUsers: `<strong>${nearbyUsersCount}</strong>`,
                  })
                : t('dashboard-scene.users-near-me.inner-card.total-text.text-plural', {
                    nearbyUsers: `<strong>${nearbyUsersCount}</strong>`,
                  })}
            </SetInnerHtmlSafe>
          </p>
        ) : (
          <p className={styles.totalText}>
            <SetInnerHtmlSafe>
              {t('dashboard-scene.users-near-me.inner-card.total-text-over-100.text', {
                'bold-text': `<strong>${t(
                  'dashboard-scene.users-near-me.inner-card.total-text-over-100.bold-text',
                )}</strong>`,
              })}
            </SetInnerHtmlSafe>
          </p>
        )}
      </section>
    </article>
  )
}

InnerCard.propTypes = {
  nearbyUsersCount: PropTypes.number.isRequired,
}

export default InnerCard
