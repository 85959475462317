import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import closeSwapModal from '@redux/thunks/collections/closeSwapModal'
import Button from 'components/common/Button'
import { ModalFooter } from 'components/common/Modal/ConfirmationModal'
import { modalKeys, modalTrackingKeys } from 'config/modals'
import { useModal } from 'hooks/application'

import styles from './Forbidden.module.scss'

const Forbidden = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { closeModal } = useModal(modalKeys.SWAP_COLLECTION)
  const handleCloseClick = () => {
    closeModal()
    dispatch(closeSwapModal())
  }

  return (
    <>
      <div className={styles.modalContent}>
        <h2>{t('your-collections.page.show.modals.swap-forbidden.title')}</h2>
        <p
          className={styles.forbiddenList}
          dangerouslySetInnerHTML={{
            __html: t('your-collections.page.show.modals.swap-forbidden.list'),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('your-collections.page.show.modals.swap-forbidden.warning'),
          }}
        />
      </div>
      <ModalFooter>
        <Button
          data-tracking={modalTrackingKeys.reject}
          onClick={handleCloseClick}
        >
          {t('your-collections.page.show.modals.swap-forbidden.button')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default Forbidden
