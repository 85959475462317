import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const TargetCollectionSelection = ({
  userId,
  nextCollection,
  value,
  groupName,
  isChecked,
  onChange,
  isDisabled,
  timezone,
}) => {
  const { t, i18n } = useTranslation()
  const userName = useSelector((state) => state.users.items[userId]?.name)
  const nextCollectionDate = DateTime.fromISO(nextCollection)

  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        <input
          className="with-gap"
          name={groupName}
          type="radio"
          value={value}
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled === true ? 'disabled' : ''}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('your-collections.page.show.swap-modal.choices-modal.swap-for-slot', {
              name: userName,
              date: DateTime.fromISO(nextCollectionDate, {
                zone: timezone,
                locale: i18n.language,
              }).toFormat('EEE, dd MMM HH:mm'),
            }),
          }}
        />
      </label>
    </li>
  )
}

TargetCollectionSelection.propTypes = {
  nextCollection: PropTypes.string.isRequired,
  userId: PropTypes.number,
  groupName: PropTypes.string.isRequired,
  value: PropTypes.number,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  timezone: PropTypes.string,
}

export default TargetCollectionSelection
