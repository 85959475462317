import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import adjustBasketArticleQuantity from '@redux/thunks/baskets/adjustBasketArticleQuantity'
import Debug from 'helpers/debug'

const debug = Debug('components:basket:article-quantity')

const DEBOUNCE_TIMEOUT = 1000
const MINIMUM_QUANTITY = 1

const ArticleQuantity = ({ basketArticleId }) => {
  const dispatch = useDispatch()
  const basketArticle = useSelector((state) => state?.baskets.articles?.[basketArticleId])
  const quantity = basketArticle?.quantity
  const article = useSelector((state) => state?.articles?.items[basketArticle?.articleId])
  const available = article?.available

  const [currentQuantity, setCurrentQuantity] = useState(() => undefined)

  const isLowerLimit = (value) => {
    return value === MINIMUM_QUANTITY
  }

  const isUpperLimit = (value) => {
    return value === available + quantity
  }

  const exceedsLowerLimit = (value) => {
    return value < MINIMUM_QUANTITY
  }

  const exceedsUpperLimit = (value) => {
    return value > quantity + available
  }

  const adjustQuantity = () => {
    dispatch(adjustBasketArticleQuantity(basketArticleId, currentQuantity)).catch(debug)
  }

  const adjustCurrentQuantityBy = (value) => {
    const newQuantity = currentQuantity + value
    if (exceedsLowerLimit(newQuantity) || exceedsUpperLimit(newQuantity)) {
      return
    }
    setCurrentQuantity(newQuantity)
  }

  useEffect(() => {
    const isInitialUpdate = currentQuantity === undefined && quantity !== undefined
    if (isInitialUpdate) {
      setCurrentQuantity(quantity)
    }
  }, [quantity])

  useEffect(() => {
    const timeoutId = setTimeout(adjustQuantity, DEBOUNCE_TIMEOUT)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [currentQuantity])

  return (
    <div className="quantity-controls">
      <button
        type="button"
        className={`article-quantity${isLowerLimit(currentQuantity) ? ' disabled' : ''}`}
        disabled={isLowerLimit(currentQuantity)}
        onClick={() => adjustCurrentQuantityBy(-1)}
      >
        -
      </button>

      <h3>{currentQuantity}</h3>

      <button
        type="button"
        className={`article-quantity${isUpperLimit(currentQuantity) ? ' disabled' : ''}`}
        disabled={isUpperLimit(currentQuantity)}
        onClick={() => adjustCurrentQuantityBy(1)}
      >
        +
      </button>
    </div>
  )
}

ArticleQuantity.propTypes = {
  basketArticleId: PropTypes.number.isRequired,
}

export { DEBOUNCE_TIMEOUT, MINIMUM_QUANTITY }

export default ArticleQuantity
