import { useMemo } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { MdErrorOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'

import Button from 'components/common/Button'
import Card from 'components/common/Cards/Card'
import { getVerifyWithOlioLink } from 'helpers/nativeAppDeepLink'
import { trackEvent } from 'services/Analytics'

import styles from './UnverifiedInfo.module.scss'

const UnverifiedInfo = () => {
  const { t } = useTranslation()

  const link = useMemo(() => {
    const olioWebSite = t('common.links.olio-website')

    if (isIOS === true || isAndroid === true) {
      return getVerifyWithOlioLink()
    } else {
      return olioWebSite
    }
  }, [isIOS, isAndroid])

  const handleVerifyButtonClick = () => {
    trackEvent('FWH_JOIN_INTRO_VERIFICATION')
  }

  return (
    <Card className={styles.unverifiedCard}>
      <Card.Heading className={styles.unverifiedCardHeading}>
        <MdErrorOutline data-testid="errorIcon" />
        {t('join_intro_page.unverified_info.title')}
      </Card.Heading>
      <Card.TextSection>
        <p>{t('join_intro_page.unverified_info.description1')}</p>
        <p>{t('join_intro_page.unverified_info.description2')}</p>
      </Card.TextSection>
      <Link
        to={link}
        reloadDocument
      >
        <Button
          secondary
          onClick={handleVerifyButtonClick}
        >
          {t('join_intro_page.unverified_info.verify_with_olio')}
        </Button>
      </Link>
    </Card>
  )
}

export default UnverifiedInfo
