import PropTypes from 'prop-types'

import StoreCard from 'components/Stores/StoreCard'
import { useAccordion } from 'hooks/application'

const StoreList = ({ storeIds }) => {
  const { handleExpandClick, handleExpandKeyDown, isExpanded } = useAccordion({
    autoExpandedSections: [],
    expandMultiple: false,
  })

  return (
    <>
      {storeIds.map((storeId) => {
        return (
          <StoreCard
            storeId={Number(storeId)}
            key={storeId}
            handleExpandClick={handleExpandClick}
            handleExpandKeyDown={handleExpandKeyDown}
            isExpanded={isExpanded}
          />
        )
      })}
    </>
  )
}

StoreList.propTypes = {
  storeIds: PropTypes.array.isRequired,
}

export default StoreList
