import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
const Checkbox = ({ checked, className, label, id, onChange, onBlur, inputRef }) => {
    return (_jsxs("label", { className: clsx('checkbox', className), htmlFor: id, children: [_jsx("input", { type: "checkbox", className: "filled-in", id: id, checked: checked, onChange: onChange, onBlur: onBlur, ref: inputRef }), _jsx("span", { className: "checkbox-label", children: label })] }));
};
Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    inputRef: PropTypes.any,
};
export default Checkbox;
