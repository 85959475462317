import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineLock } from 'react-icons/md'

import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import { INFO } from 'config/alert'
import { trackEvent } from 'services/Analytics'

const InductionQuizButtons = ({
  isResultsStep,
  paginate,
  onSubmit,
  step,
  questions,
  retryQuiz,
  isRetryLocked,
  inductionId,
  inductionTitle,
}) => {
  const { t } = useTranslation()
  const [quizRetryAttempted, setQuizRetryAttempted] = useState(false)

  const handleRetry = () => {
    setQuizRetryAttempted(false)
    retryQuiz()
  }

  const handleRetryAttempt = () => {
    setQuizRetryAttempted(true)
    trackEvent('INDUCTION_QUIZ_RETRY', {
      induction_title: inductionTitle,
      induction_id: inductionId,
      button_state: 'inactive',
      video: true,
    })
  }

  if (isResultsStep === true) {
    return (
      <div className="induction-retry-quiz">
        {isRetryLocked === true ? (
          <>
            {quizRetryAttempted === true && (
              <div className="alert-info-message">
                <Alert variant={INFO}>
                  {t('inductions.page.show.quiz.play_video_instruction')}
                </Alert>
              </div>
            )}
            <Button
              className="try-quiz-again"
              secondary
              onClick={handleRetryAttempt}
              leftContent={<MdOutlineLock />}
            >
              {t('inductions.page.show.quiz.try_quiz_again')}
            </Button>
          </>
        ) : (
          <Button
            className="try-quiz-again"
            secondary
            onClick={handleRetry}
          >
            {t('inductions.page.show.quiz.try_quiz_again')}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className="induction-quiz-buttons">
      <Button
        className={clsx({ 'hide-button': step <= 0 })}
        secondary
        onClick={() => paginate(-1)}
      >
        {t('inductions.page.show.quiz.previous')}
      </Button>
      {step + 1 === questions.length ? (
        <Button
          className="check-answers-button"
          onClick={onSubmit}
        >
          {t('inductions.page.show.quiz.button-submit')}
        </Button>
      ) : (
        <Button onClick={() => paginate(1)}>{t('inductions.page.show.quiz.next')}</Button>
      )}
    </div>
  )
}

InductionQuizButtons.propTypes = {
  isResultsStep: PropTypes.bool.isRequired,
  paginate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  retryQuiz: PropTypes.func.isRequired,
  isRetryLocked: PropTypes.bool.isRequired,
  inductionId: PropTypes.number.isRequired,
  inductionTitle: PropTypes.string.isRequired,
}

export default InductionQuizButtons
