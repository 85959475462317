import PropTypes from 'prop-types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHowToReg } from 'react-icons/md'

import Button from 'components/common/Button'
import { modalKeys } from 'config/modals'
import { useModal } from 'hooks/application'

const { CLOSE_BASKET_CONFIRMATION } = modalKeys

const Footer = ({ basketId }) => {
  const { t } = useTranslation()
  const { openModal } = useModal(CLOSE_BASKET_CONFIRMATION)

  return (
    <Button
      className="mark-collected-btn"
      leftContent={<MdHowToReg />}
      onClick={() => openModal({ basketId })}
    >
      {t('baskets.collected')}
    </Button>
  )
}

Footer.propTypes = {
  basketId: PropTypes.number,
}

export default memo(Footer)
