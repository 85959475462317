import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useModalLockBodyScroll } from 'hooks/application';
import { trackEvent } from 'services/Analytics';
import styles from './GenericModal.module.scss';
const GenericModal = ({ closeModal, id, className, children, modalKey }) => {
    useModalLockBodyScroll();
    const modalRef = useRef();
    const allFocusableElementsRef = useRef();
    useEffect(() => {
        allFocusableElementsRef.current = modalRef?.current.querySelectorAll(`button:not([disabled]), [href], input:not([disabled]), 
    select, textarea, [tabindex]:not([tabindex="-1"])`);
        allFocusableElementsRef.current[0]?.focus();
    }, [modalRef.current]);
    useEffect(() => {
        const firstFocusableElement = allFocusableElementsRef.current[0];
        if (firstFocusableElement !== undefined) {
            firstFocusableElement.focus();
        }
    }, [allFocusableElementsRef.current]);
    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            allFocusableElementsRef.current = modalRef?.current.querySelectorAll(`button:not([disabled]), [href], input:not([disabled]), 
      select, textarea, [tabindex]:not([tabindex="-1"])`);
            const allFocusableElements = allFocusableElementsRef.current;
            const first = allFocusableElements[0];
            const last = allFocusableElements[allFocusableElements.length - 1];
            if (event.shiftKey) {
                if (document.activeElement === first) {
                    event.preventDefault();
                    last.focus();
                }
            }
            else {
                if (document.activeElement === last) {
                    event.preventDefault();
                    first.focus();
                }
            }
        }
    };
    const handleOverlayClick = () => {
        if (modalKey) {
            trackEvent('MODAL_DISMISS_OVERLAY', { modalKey });
        }
        closeModal();
    };
    return (_jsxs("div", { className: styles.modalContainer, onKeyDown: handleKeyDown, children: [_jsx("div", { className: "overlay", onClick: handleOverlayClick, "data-testid": "modal-overlay" }), _jsx("div", { id: id, className: clsx(styles.genericModal, className), ref: modalRef, "data-testid": "generic-modal", role: "dialog", children: children })] }));
};
GenericModal.propTypes = {
    closeModal: PropTypes.func,
    id: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    modalKey: PropTypes.string,
};
export default GenericModal;
