import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineSchedule } from 'react-icons/md'
import { Link } from 'react-router-dom'

import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import StoreInfo from 'components/common/StoreInfo/StoreInfo'
import { MY_COLLECTIONS_URL } from 'config/routes'
import { formatShortDateAndTime24 } from 'helpers/time'
import EmptyStateCollectionCard from 'images/EmptyStateCollectionCard.png'
import { trackEvent } from 'services/Analytics'

import styles from './CollectionCard.module.scss'

const CollectionCard = ({ collection, index }) => {
  const { t } = useTranslation()
  const { collectionId, business, store, storeId, nextCollection } = collection || {}

  const nextCollectionDate = useMemo(() => {
    if (collection !== undefined) {
      return formatShortDateAndTime24(nextCollection, store?.timezone)
    }
  }, [collection, nextCollection, store?.timezone])

  return (
    <article
      className={clsx(
        collection === undefined
          ? styles.noCollectionContainer
          : [styles.collectionContainer, 'card'],
      )}
      data-testid={index !== undefined && `collection-card-${index}`}
    >
      {collection === undefined ? (
        <img
          alt=""
          src={EmptyStateCollectionCard}
        />
      ) : (
        <Link
          className={styles.cardLink}
          to={`${MY_COLLECTIONS_URL}/${collectionId}`}
          onClick={() => trackEvent('FWH_DASHBOARD_CLICK_INDIVIDUAL_COLLECTION')}
        >
          <StoreInfo
            logoUrl={business?.logo.url}
            location={store?.location ?? {}}
            storeId={storeId}
            small
            addressLink={false}
          />
          <div className={styles.collectionInfo}>
            <span className={styles.icon}>
              <MdOutlineSchedule />
            </span>
            <SetInnerHtmlSafe>
              {t('dashboard-scene.your-collection-slots.collection-card.next-collection', {
                date: `<strong>${nextCollectionDate}</strong>`,
              })}
            </SetInnerHtmlSafe>
          </div>
        </Link>
      )}
    </article>
  )
}

CollectionCard.propTypes = {
  collection: PropTypes.object,
  index: PropTypes.number,
}

export default CollectionCard
