import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useAppDispatch } from '@redux/hooks';
import fetchMyCollections from '@redux/thunks/collections/fetchMyCollections';
import { COLLECTION_FREQUENCIES, COLLECTION_TYPES } from 'config/collections';
import { LUXON_FREQUENCY_MAP } from 'helpers/collections';
import Debug from 'helpers/debug';
import { useCollection, useMyCollections } from 'hooks/collections';
const debug = Debug('components:available-collections:claim-collection:useHasCollectionOnSameDay');
const MAX_COLLECTION_ITERATION_COUNT = 7;
const areOnSameDay = (firstDate, secondDate) => {
    return firstDate.hasSame(secondDate, 'day');
};
/**
 * @param {number} collectionId
 * @returns {boolean} true or false depending on whether the current user already has a collection
 * with a future instance that matches the date of the collection with the passed collectionId
 */
const useHasCollectionOnSameDay = (collectionId) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchMyCollections()).catch(debug);
    }, []);
    const { data: currentCollection } = useCollection({ collectionId });
    const myCollections = useMyCollections();
    const hasCollectionOnSameDay = useMemo(() => {
        if (currentCollection === undefined) {
            return false;
        }
        const currentNextCollectionDate = DateTime.fromISO(currentCollection.nextCollection);
        return myCollections.some((collection) => {
            const nextCollectionDate = DateTime.fromISO(collection.nextCollection);
            if (areOnSameDay(nextCollectionDate, currentNextCollectionDate)) {
                return true;
            }
            if (collection.type === COLLECTION_TYPES.ONGOING) {
                for (let iteration = 1; iteration <= MAX_COLLECTION_ITERATION_COUNT; iteration++) {
                    if (collection.frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
                        const nextIterationDate = nextCollectionDate.plus({ week: iteration * 2 });
                        if (areOnSameDay(nextIterationDate, currentNextCollectionDate)) {
                            return true;
                        }
                    }
                    else if (collection.frequency === COLLECTION_FREQUENCIES.DAILY ||
                        collection.frequency === COLLECTION_FREQUENCIES.WEEKLY ||
                        collection.frequency === COLLECTION_FREQUENCIES.MONTHLY) {
                        const nextIterationDate = nextCollectionDate.plus({
                            [LUXON_FREQUENCY_MAP[collection.frequency]]: iteration,
                        });
                        if (areOnSameDay(nextIterationDate, currentNextCollectionDate)) {
                            return true;
                        }
                    }
                    else {
                        return false;
                    }
                }
            }
            return false;
        });
    }, [myCollections, currentCollection?.nextCollection]);
    const isDataLoaded = myCollections.length > 0 && currentCollection !== undefined;
    return isDataLoaded ? hasCollectionOnSameDay : null;
};
export default useHasCollectionOnSameDay;
