import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import MyCollectionCardList from 'components/Collections/MyCollectionCardList'
import SceneHeader from 'components/common/SceneHeader'

const MyCollectionsScene = () => {
  const { t } = useTranslation()

  return (
    <div id="my-collections-scene">
      <SceneHeader title={t('your-collections.page.index.title')} />
      <div>{t('your-collections.page.index.description')}</div>
      <MyCollectionCardList />
    </div>
  )
}

export default memo(MyCollectionsScene)
