import PropTypes from 'prop-types'

import { useAccordion } from 'hooks/application'

const Accordion = ({
  sections,
  autoExpandedSections,
  Header,
  Content,
  Footer,
  ItemWrapper = 'div',
  AccordionWrapper = 'div',
  expandMultiple = false,
}) => {
  const { handleMouseDown, handleExpandClick, handleExpandKeyDown, isExpanded } = useAccordion({
    autoExpandedSections,
    expandMultiple,
    isClickableHeader: true,
  })

  return (
    <AccordionWrapper>
      {sections.map((section) => (
        <ItemWrapper
          key={section.id}
          id={section.id}
        >
          <button
            type="button"
            className="cursor-pointer no-button-styling full-width-button button-focus"
            data-testid="headerWrapper"
            onMouseDown={handleMouseDown}
            onClick={(event) => handleExpandClick(event, section.id)}
            onKeyDown={(event) => handleExpandKeyDown(event, section.id)}
            tabIndex="0"
          >
            <Header
              section={section}
              active={isExpanded(section.id)}
            />
          </button>
          {isExpanded(section.id) === true && <Content section={section} />}
          <Footer basketId={section.id} />
        </ItemWrapper>
      ))}
    </AccordionWrapper>
  )
}

Accordion.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
  autoExpandedSections: PropTypes.arrayOf(PropTypes.any),
  Header: PropTypes.object.isRequired,
  Content: PropTypes.object.isRequired,
  Footer: PropTypes.object.isRequired,
  ItemWrapper: PropTypes.any,
  AccordionWrapper: PropTypes.any,
  expandMultiple: PropTypes.bool,
}

export default Accordion
