import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import Card from 'components/common/Cards/Card';
import carrotWithStore from 'images/carrotWithStore.webp';
import { trackEvent } from 'services/Analytics';
import styles from './NoCollectionsNearMe.module.scss';
const NoCollectionsNearMe = ({ collectionCount }) => {
    const { t } = useTranslation();
    return (_jsxs(Card, { className: styles.noCollectionsNearMe, children: [_jsx(Card.Heading, { children: t('available-collections.no-collections-near-me.heading') }), _jsx(Card.Image, { children: carrotWithStore }), _jsxs(Card.TextSection, { className: styles.textSection, children: [_jsx("strong", { className: styles.subheading, children: collectionCount === 0
                            ? t('available-collections.no-collections-near-me.subheading')
                            : t('available-collections.no-collections-near-me.subheadingFewCollections') }), _jsx("p", { children: t('available-collections.no-collections-near-me.description1') }), _jsx("p", { children: t('available-collections.no-collections-near-me.description2') }), _jsx("p", { children: t('available-collections.no-collections-near-me.description3') })] }), _jsx(Button, { className: styles.becomeAnAmbassadorButton, href: t('external-urls.squad-starter-link'), onClick: () => trackEvent('FWH_BECOME_AMBASSADOR'), target: "_blank", children: t('available-collections.no-collections-near-me.buttonLabel') })] }));
};
export default NoCollectionsNearMe;
