import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { memo } from 'react'

import { BASKET_CLOSE as ANIMATION_OPTIONS } from 'helpers/animations'

const AnimationWrapper = ({ children }) => {
  return (
    <motion.div
      className="basket-container"
      transition={{ duration: ANIMATION_OPTIONS.duration }}
      animate="visible"
      exit="exit"
      variants={ANIMATION_OPTIONS.variants}
    >
      <div className="card">{children}</div>
    </motion.div>
  )
}

AnimationWrapper.propTypes = {
  children: PropTypes.node,
}

export default memo(AnimationWrapper)
