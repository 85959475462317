import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdErrorOutline, MdOutlineCheckCircle } from 'react-icons/md'

import Badge from 'components/common/Badge'
import { Stack } from 'components/layout/primitives'
import { trackEvent } from 'services/Analytics'

const QuizQuestion = ({
  index,
  questionText,
  answers,
  questionCount,
  isInductionCompleted,
  isRetry,
  answersChanged,
  setAnswersChanged,
  setCorrectAnswerAlert,
}) => {
  const { t } = useTranslation()
  const { register, getValues } = useFormContext()

  const handleAnswerSelection = (answer) => {
    if (isAnswerCorrect() === false) {
      setAnswersChanged({
        ...answersChanged,
        [index]: true,
      })
    }
    trackEvent('INDUCTION_QUIZ_ANSWER', {
      question: questionText,
      answer: answer.body,
      refresh: isInductionCompleted,
    })
  }

  const isAnswerCorrect = () => {
    const correctAnswer = answers.find(({ isCorrect }) => isCorrect === true)
    const userAnswer = getValues()[`question-${index}`]
    if (correctAnswer.body === userAnswer) {
      return true
    }
    return false
  }

  const displayBadge = () => {
    if (isAnswerCorrect() === true) {
      return (
        <Badge
          icon={<MdOutlineCheckCircle />}
          text={t('inductions.page.show.quiz.success_badge')}
          variant="success"
        />
      )
    } else if (isAnswerCorrect() === false) {
      return (
        <Badge
          icon={<MdErrorOutline />}
          text={t('inductions.page.show.quiz.error_badge')}
          variant="error"
        />
      )
    }
    return null
  }

  useEffect(() => {
    if (isAnswerCorrect() === true && isRetry === true && answersChanged[index] === undefined) {
      setCorrectAnswerAlert(true)
    }
  }, [])

  return (
    <div
      className="card"
      style={{ padding: '1rem' }}
    >
      <Stack gap="1rem">
        <Stack gap="0.5rem">
          <h2 className="quiz-question-heading">
            {t('inductions.page.show.quiz.question', { count: index + 1 })}
          </h2>
          <strong className="quiz-question-count">
            {t('inductions.page.show.quiz.of', { count: questionCount })}
          </strong>
        </Stack>
        <h3>{questionText}</h3>
        <div>
          {answers.map((answer, answerIndex) => {
            return (
              <div
                className="quiz-answer"
                key={answerIndex}
              >
                <label>
                  <input
                    {...register(`question-${index}`, { required: true })}
                    className="with-gap"
                    type="radio"
                    value={answer.body}
                    onClick={() => handleAnswerSelection(answer)}
                    disabled={isRetry && isAnswerCorrect() && answersChanged[index] === undefined}
                  />
                  <span>{answer.body}</span>
                </label>
              </div>
            )
          })}
          {isRetry === true && answersChanged[index] === undefined && displayBadge()}
        </div>
      </Stack>
    </div>
  )
}

QuizQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      isCorrect: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  questionCount: PropTypes.number.isRequired,
  isInductionCompleted: PropTypes.bool.isRequired,
  isRetry: PropTypes.bool.isRequired,
  answersChanged: PropTypes.object.isRequired,
  setAnswersChanged: PropTypes.func.isRequired,
  setCorrectAnswerAlert: PropTypes.func.isRequired,
}

export default QuizQuestion
