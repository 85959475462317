import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const SCROLL_START_THRESHOLD = 10;
const useAccordion = ({ autoExpandedSections, expandMultiple, isClickableHeader = false }) => {
    const [scrollXoffset, setScrollXoffset] = useState(0);
    const [expandedSections, setExpandedSections] = useState(undefined);
    const handleMouseDown = (event) => {
        setScrollXoffset(event.screenX);
    };
    const getNextExpandedSections = (sectionId) => {
        if (isExpanded(sectionId) === true) {
            return expandedSections.filter((a) => a !== sectionId);
        }
        if (expandMultiple === true && expandedSections !== undefined) {
            return [...expandedSections, sectionId];
        }
        return [sectionId];
    };
    const handleExpandClick = (event, sectionId) => {
        if (isClickableHeader === true) {
            const delta = Math.abs(event.screenX - scrollXoffset);
            const hasScrolled = delta > SCROLL_START_THRESHOLD;
            if (hasScrolled === false) {
                setExpandedSections(getNextExpandedSections(sectionId));
            }
        }
        else {
            setExpandedSections(getNextExpandedSections(sectionId));
        }
    };
    const handleExpandKeyDown = (event, sectionId) => {
        if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();
            setExpandedSections(getNextExpandedSections(sectionId));
        }
    };
    const isExpanded = (id) => {
        return expandedSections?.includes(id) === true;
    };
    useEffect(() => {
        const isInitialUpdate = autoExpandedSections.length > 0 && expandedSections === undefined;
        if (isInitialUpdate === true) {
            setExpandedSections(autoExpandedSections);
        }
    }, [autoExpandedSections]);
    return {
        isExpanded,
        handleMouseDown,
        handleExpandClick,
        handleExpandKeyDown,
    };
};
useAccordion.propTypes = {
    autoExpandedSections: PropTypes.array,
    expandMultiple: PropTypes.bool.isRequired,
    isClickableHeader: PropTypes.bool,
};
export default useAccordion;
