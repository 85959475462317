import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdInsertLink, MdPictureAsPdf, MdSlideshow, MdSubject } from 'react-icons/md'

import Debug from 'helpers/debug'
import { getDocuments } from 'helpers/documents'
import { appendUniqueIdToTypeformUrl } from 'helpers/typeform'

import styles from './HelpfulInformation.module.scss'

const debug = Debug('components:collections:helpful-information')

const DocumentIcon = {
  picture_as_pdf: <MdPictureAsPdf />,
  slideshow: <MdSlideshow />,
  link: <MdInsertLink />,
  subject: <MdSubject />,
}

const HelpfulInformation = () => {
  const { t } = useTranslation()
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const docs = await getDocuments()
        const enhancedDocuments = docs.map((document) => {
          if (document.url.includes('olio1.typeform')) {
            return {
              ...document,
              url: appendUniqueIdToTypeformUrl(document.url),
            }
          }
          return document
        })

        setDocuments(enhancedDocuments)
      } catch (error) {
        debug(error)
      }
    }
    fetchDocuments()
  }, [])

  const importantDocuments = documents.filter((document) => document.important === true)

  return (
    <div className={styles.root}>
      <div className="card">
        <h3 className={styles.title}>
          {t('your-collections.page.index.subtitles.helpful-information')}
        </h3>
        <ul className={styles.documentsList}>
          {importantDocuments.map((importantDoc) => {
            return (
              <li
                key={importantDoc.id}
                className={styles.listItem}
              >
                <span
                  className={styles.icon}
                  data-testid="icon"
                >
                  {DocumentIcon[importantDoc.icon]}
                </span>
                <a
                  href={importantDoc.url}
                  className={styles.label}
                >
                  {importantDoc.label}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default HelpfulInformation
