import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useAppSelector } from '@redux/hooks';
import RotaItem from 'components/common/StoreRota/RotaItem';
import { sortRotaItemsByDate } from 'helpers/collections';
import styles from './Rota.module.scss';
const Rota = ({ storeId, className, timezone }) => {
    const storeCollectionIds = useAppSelector((state) => state.stores.items[storeId]?.collections);
    const storeCollections = useAppSelector((state) => state?.collections?.items);
    const sortedStoreCollections = useMemo(() => {
        if (storeCollectionIds !== undefined && storeCollections !== undefined) {
            return sortRotaItemsByDate(storeCollectionIds, storeCollections);
        }
    }, [storeCollectionIds, storeCollections]);
    return (_jsx("div", { className: clsx(className, styles.rota), children: sortedStoreCollections !== undefined &&
            sortedStoreCollections.map((item, index) => {
                const { nextCollection, userId, collectionState } = item;
                return (_jsx(RotaItem, { nextCollection: nextCollection, userId: userId, collectionState: collectionState, timezone: timezone }, index));
            }) }));
};
Rota.propTypes = {
    storeId: PropTypes.number.isRequired,
    className: PropTypes.string,
    timezone: PropTypes.string,
};
export default Rota;
