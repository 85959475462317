import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences'
import BecomeAFoodWasteHero from 'components/JoinIntro/BecomeAFoodWasteHero/BecomeAFoodWasteHero'
import CollectionsNearMe from 'components/JoinIntro/CollectionsNearMe/CollectionsNearMe'
import NextSteps from 'components/JoinIntro/NextSteps'
import RegisteringIsEasy from 'components/JoinIntro/RegisteringIsEasy'
import UnverifiedInfo from 'components/JoinIntro/UnverifiedInfo'
import VolunteeringInfoSection from 'components/common/VolunteeringInfoSection'
import Footer from 'components/layout/Footer'
import Debug from 'helpers/debug'
import { useAuthenticatedUser } from 'hooks/users'

import styles from './JoinIntroScene.module.scss'

const debug = Debug('scenes:join-intro-scene')

const JoinIntroScene = () => {
  const dispatch = useDispatch()
  const { isUserVerified } = useAuthenticatedUser()

  useEffect(() => {
    dispatch(fetchUserPreferences()).catch(debug)
  }, [])

  return (
    <>
      <div
        id="join-intro-scene"
        className={styles.joinIntroScene}
      >
        {isUserVerified === false && <UnverifiedInfo />}
        <BecomeAFoodWasteHero isUserVerified={isUserVerified} />
        <VolunteeringInfoSection />
        <CollectionsNearMe />
        <NextSteps />
        <RegisteringIsEasy isUserVerified={isUserVerified} />
      </div>
      <Footer />
    </>
  )
}

export default JoinIntroScene
