import PropTypes from 'prop-types'

const Spacer = ({ x = '0', y = '0' }) => {
  return <div style={{ height: x, width: y }} />
}

Spacer.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
}

export default Spacer
