import { BECOME_SQUAD_CAPTAIN_FAIL, BECOME_SQUAD_CAPTAIN_SUCCESS, BUSINESSES_FETCH_START, BUSINESSES_FETCH_SUCCESS, COLLECTIONS_FETCH_SUCCESS, COLLECTION_FETCH_SUCCESS, COLLECTION_SWAP_SUCCESS, STEP_DOWN_AS_SQUAD_CAPTAIN_FAIL, STEP_DOWN_AS_SQUAD_CAPTAIN_SUCCESS, STORES_CLEAR_ERROR, STORES_FETCH_START, STORES_FETCH_SUCCESS, STORE_FETCH_START, STORE_FETCH_SUCCESS, } from '@redux/actionTypes';
import { FETCHING_STATUSES } from 'config/api';
import { getCurrentUserId } from 'helpers/user';
import initialState from './initialState';
const { LOADING, SUCCESS } = FETCHING_STATUSES;
const ACTION_HANDLERS = {
    [COLLECTION_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { collectionId, storeId } = payload;
        let newStoreCollections = state.items[storeId]?.collections || [];
        if (newStoreCollections.indexOf(collectionId) === -1) {
            newStoreCollections = [...newStoreCollections, collectionId];
        }
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...(state.items[storeId] || {}),
                    collections: [...newStoreCollections],
                },
            },
        };
    },
    [COLLECTIONS_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { collections } = payload;
        const newItems = { ...state.items };
        collections.forEach((collection) => {
            const store = { ...newItems[collection.storeId] };
            newItems[collection.storeId] = {
                ...store,
                collections: store.collections || [],
            };
            if (newItems[collection.storeId].collections.indexOf(collection.collectionId) === -1) {
                newItems[collection.storeId].collections = [
                    ...newItems[collection.storeId].collections,
                    collection.collectionId,
                ];
            }
        });
        return {
            ...state,
            items: newItems,
        };
    },
    [COLLECTION_SWAP_SUCCESS]: (state = initialState, action) => {
        const { collectionId, swapForCollectionId, storeId } = action.payload;
        const currentStoreCollections = state.items[storeId]?.collections || [];
        const newStoreCollections = currentStoreCollections.filter((id) => id !== collectionId && id !== swapForCollectionId);
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...(state.items[storeId] || {}),
                    collections: [...newStoreCollections],
                },
            },
        };
    },
    [STORES_FETCH_START]: (state = initialState) => {
        return {
            ...state,
            storesFetchingStatus: LOADING,
        };
    },
    [STORE_FETCH_START]: (state = initialState) => {
        return {
            ...state,
        };
    },
    [STORE_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { name, businessId, businessName, address, storeId, storeNotes, businessNotes, timezone, location, squadCaptain, } = payload;
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...state.items[storeId],
                    name,
                    businessId,
                    address,
                    notes: storeNotes,
                    timezone,
                    location,
                    squadCaptain,
                },
            },
            businesses: {
                ...state.businesses,
                [businessId]: {
                    ...state.businesses[businessId],
                    name: businessName,
                    notes: businessNotes,
                },
            },
        };
    },
    [STORES_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload: { stores, onlyMyStores }, } = action;
        const newStores = {};
        stores.forEach((store) => {
            const { storeId, businessId, name, address, storeNotes, timezone, location, squadCaptain } = store;
            newStores[storeId] = {
                ...state.items[storeId],
                name,
                businessId,
                address,
                notes: storeNotes,
                timezone,
                location,
                squadCaptain,
                isMyStore: onlyMyStores ?? state.items[storeId]?.isMyStore ?? false,
            };
        });
        return {
            ...state,
            storesFetchingStatus: SUCCESS,
            items: {
                ...state.items,
                ...newStores,
            },
        };
    },
    [BUSINESSES_FETCH_START]: (state = initialState) => {
        return {
            ...state,
            businessesFetchingStatus: LOADING,
        };
    },
    [BUSINESSES_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload: { businesses }, } = action;
        const newBusinesses = {};
        businesses.forEach((business) => {
            newBusinesses[business.id] = {
                name: business.name,
                logo: business.logo,
                notes: business.notes,
            };
        });
        return {
            ...state,
            businesses: {
                ...state.businesses,
                ...newBusinesses,
            },
            businessesFetchingStatus: SUCCESS,
        };
    },
    [BECOME_SQUAD_CAPTAIN_SUCCESS]: (state = initialState, action) => {
        const { storeId } = action.payload;
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...state.items[storeId],
                    squadCaptain: {
                        id: getCurrentUserId(),
                    },
                },
            },
        };
    },
    [STEP_DOWN_AS_SQUAD_CAPTAIN_SUCCESS]: (state = initialState, action) => {
        const { storeId } = action.payload;
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...state.items[storeId],
                    squadCaptain: {
                        id: null,
                        firstName: null,
                        photo: null,
                    },
                },
            },
        };
    },
    [BECOME_SQUAD_CAPTAIN_FAIL]: (state = initialState, action) => {
        const { storeId } = action.payload;
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...state.items[storeId],
                    errors: true,
                },
            },
        };
    },
    [STEP_DOWN_AS_SQUAD_CAPTAIN_FAIL]: (state = initialState, action) => {
        const { storeId } = action.payload;
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...state.items[storeId],
                    errors: true,
                },
            },
        };
    },
    [STORES_CLEAR_ERROR]: (state = initialState, action) => {
        const { storeId } = action.payload;
        return {
            ...state,
            items: {
                ...state.items,
                [storeId]: {
                    ...state.items[storeId],
                    errors: undefined,
                },
            },
        };
    },
};
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
