import PropTypes from 'prop-types'

const StepContentSelection = ({
  groupName,
  value,
  isChecked,
  isDisabled,
  displayComponent,
  onChange,
}) => {
  return (
    <li>
      <label data-testid={`option-${value}`}>
        <input
          className="with-gap"
          name={groupName}
          type="radio"
          value={value}
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled === true ? 'disabled' : ''}
        />
        {displayComponent}
      </label>
    </li>
  )
}

StepContentSelection.propTypes = {
  groupName: PropTypes.string,
  value: PropTypes.any,
  displayComponent: PropTypes.node,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default StepContentSelection
