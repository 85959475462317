import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { MdLogin, MdLogout, MdMenu } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Link, matchRoutes, useLocation } from 'react-router-dom'

import toggleSidebar from '@redux/thunks/application/toggleSidebar'
import logout from '@redux/thunks/users/logout'
import { nonFoodHeroRoutes } from 'helpers/navigation'
import { useIsMobile } from 'hooks/application'
import { useIsUserAuthenticated } from 'hooks/users'
import LogoImage from 'images/olio-logo-header.svg'
import { trackEvent } from 'services/Analytics'

import styles from './Navigation.module.scss'

const Navigation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const isMobile = useIsMobile()
  const isUserAuthenticated = useIsUserAuthenticated()
  const nonFoodHeroMatchedRoutes = matchRoutes(nonFoodHeroRoutes, location)
  const dispayBurgerMenu =
    nonFoodHeroMatchedRoutes === null || nonFoodHeroMatchedRoutes.length === 0

  const handleToggle = () => {
    dispatch(toggleSidebar())
  }

  const handleLogout = () => {
    const eventName = `NAVIGATE_LOGOUT`
    trackEvent(eventName, {
      origin: 'top-nav',
    })

    dispatch(logout())
  }

  return (
    <nav>
      <div className={styles.navWrapper}>
        {isMobile === true && dispayBurgerMenu === true && (
          <button
            type="button"
            className={clsx('sidebar-toggle cursor-pointer no-button-styling', styles.navButton)}
            onClick={handleToggle}
            onKeyPress={handleToggle}
            aria-label={t('layouts.authenticated.sidebar_toggle')}
            tabIndex="0"
          >
            <span>
              <MdMenu aria-hidden="true" />
            </span>
          </button>
        )}
        <div className={styles.logoContainer}>
          <a
            href={t('layouts.join-default.header-link')}
            className={styles.brandLogo}
          >
            <img
              alt=""
              src={LogoImage}
            />
            {isMobile === false && <h2>{t('layouts.join-default.header')}</h2>}
          </a>
        </div>
        {isMobile === false && (
          <ul>
            <li>
              <a
                className={styles.website}
                href={t('external-urls.olio-website')}
              >
                {t('phrases.olio-website')}
              </a>
            </li>
            {isUserAuthenticated === false && (
              <li>
                <Link
                  to="/login"
                  className={styles.authenticationWrapper}
                >
                  <div className={styles.authentication}>
                    <MdLogin />
                    <span className={styles.authenticationText}>{t('phrases.login')}</span>
                  </div>
                </Link>
              </li>
            )}
            {isUserAuthenticated === true && (
              <>
                <li>
                  <button
                    className={clsx('no-button-styling', styles.authenticationWrapper)}
                    type="button"
                    onClick={handleLogout}
                  >
                    <div className={styles.authentication}>
                      <MdLogout />
                      <span className={styles.authenticationText}>{t('phrases.logout')}</span>
                    </div>
                  </button>
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    </nav>
  )
}

export default Navigation
