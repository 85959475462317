import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import styles from './SceneHeader.module.scss';
const SceneHeader = ({ title, description }) => {
    return (_jsxs("div", { className: styles.sceneHeader, children: [_jsx("h1", { children: title }), description && _jsx("p", { children: description })] }));
};
SceneHeader.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
};
export default SceneHeader;
