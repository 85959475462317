import { useTranslation } from 'react-i18next'

import FacebookIcon from 'images/facebook_circle.svg'
import InstagramIcon from 'images/instagram_circle.svg'
import TwitterIcon from 'images/twitter_circle.svg'

import styles from './Footer.module.scss'

const Footer = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()

  return (
    <footer data-testid="footer">
      <div className={styles.socialFooter}>
        <ul>
          <li>
            <a
              href={t('external-urls.instagram')}
              target="_blank"
              data-tracking-event="NAVIGATE_INSTAGRAM"
              data-tracking-properties='{"origin": "footer"}'
              rel="noreferrer"
              data-testid="instagram-footer"
            >
              <img
                alt="Instagram"
                src={InstagramIcon}
              />
            </a>
          </li>
          <li className={styles.twitter}>
            <a
              href={t('external-urls.twitter')}
              target="_blank"
              data-tracking-event="NAVIGATE_TWITTER"
              data-tracking-properties='{"origin": "footer"}'
              rel="noreferrer"
              data-testid="twitter-footer"
            >
              <img
                alt="Twitter"
                src={TwitterIcon}
              />
            </a>
          </li>
          <li>
            <a
              href={t('external-urls.facebook')}
              target="_blank"
              data-tracking-event="NAVIGATE_FACEBOOK"
              data-tracking-properties='{"origin": "footer"}'
              rel="noreferrer"
              data-testid="facebook-footer"
            >
              <img
                alt="Facebook"
                src={FacebookIcon}
              />
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.copyrightFooter}>
        <div className={styles.copyright}>
          {t('company.name')} © {year}
        </div>
      </div>
    </footer>
  )
}

export default Footer
