import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './Inline.module.scss';
const Inline = ({ children, gap }) => {
    const style = { '--gap': gap };
    const childrenWithClass = React.Children.map(children, (child) => React.cloneElement(child, {
        className: `${child.props.className} ${styles.child}`,
    }));
    return (_jsx("div", { className: styles.root, style: style, children: childrenWithClass }));
};
export default Inline;
