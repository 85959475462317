import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHelpOutline } from 'react-icons/md'
import { useDispatch } from 'react-redux'

import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses'
import fetchMyStores from '@redux/thunks/stores/fetchMyStores'
import StoreList from 'components/Stores/StoreList'
import CollapsibleAlert from 'components/common/CollapsibleAlert'
import InfoItem, { infoItemTypes } from 'components/common/InfoItem'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import { MESSAGE } from 'config/alert'
import Debug from 'helpers/debug'
import { useMyStores } from 'hooks/stores'
import PeachIcon from 'images/Peach.svg'

const debug = Debug('scenes:stores-scene')

const StoresScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data: stores, isLoading } = useMyStores()

  const storeIds = useMemo(() => stores.map((store) => store.id), [stores])
  const businessIds = useMemo(() => stores.map((store) => store.businessId), [stores])
  const uniqueBusinessIds = useMemo(() => [...new Set(businessIds)], [businessIds.length])

  useEffect(() => {
    dispatch(fetchMyStores()).catch(debug)
  }, [])

  useEffect(() => {
    if (uniqueBusinessIds !== undefined && uniqueBusinessIds.length > 0) {
      dispatch(fetchBusinesses(uniqueBusinessIds)).catch(debug)
    }
  }, [uniqueBusinessIds.length])

  return (
    <div id="stores-scene">
      <SceneHeader
        title={t('stores.page.title')}
        description={t('stores.page.description')}
      />
      <div className="squad-captain-card">
        <CollapsibleAlert
          icon={<MdHelpOutline data-testid="helpIcon" />}
          title={t('stores.page.squad_captain_card.title')}
          description={t('stores.page.squad_captain_card.description_collapsed')}
          variant={MESSAGE}
        >
          <p className="list-header">
            {t('stores.page.squad_captain_card.description_expanded.header')}
          </p>
          <ul>
            <li>{t('stores.page.squad_captain_card.description_expanded.point1')}</li>
            <li>{t('stores.page.squad_captain_card.description_expanded.point2')}</li>
            <li>{t('stores.page.squad_captain_card.description_expanded.point3')}</li>
          </ul>
        </CollapsibleAlert>
      </div>

      <h2>{t('stores.page.stores_section_heading')}</h2>
      {isLoading === true && <LoadingSpinner containerHeight="400px" />}
      {isLoading === false && stores.length > 0 && <StoreList storeIds={storeIds} />}
      {isLoading === false && stores.length === 0 && (
        <InfoItem
          type={infoItemTypes.EMPTY}
          title={t('stores.page.no_collection_card.title')}
          description1={t('stores.page.no_collection_card.description')}
          icon={PeachIcon}
        />
      )}
    </div>
  )
}

export default StoresScene
