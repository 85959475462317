import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { alertIcons, alertVariants } from 'config/alert';
import styles from './Alert.module.scss';
const Alert = ({ className, variant, children }) => {
    const Icon = alertIcons[variant];
    return (_jsxs("div", { className: clsx(styles.alert, styles[variant], className, 'alert'), children: [Icon && (_jsx("span", { className: clsx(styles.alertIcon), children: _jsx(Icon, { "data-testid": `${variant}Icon` }) })), _jsx("div", { className: styles.children, children: children })] }));
};
Alert.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(alertVariants).isRequired,
    children: PropTypes.node,
};
export default Alert;
