import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { MdOutlinePlace } from 'react-icons/md';
const Location = ({ storeAddress, type }) => {
    return (_jsxs("div", { className: "info", children: [_jsx(MdOutlinePlace, { className: clsx('information-icons', type), "data-testid": "locationIcon" }), _jsx("address", { className: "store-address", children: storeAddress &&
                    Object.values(storeAddress).map((addressLine, index) => {
                        return _jsx("span", { children: addressLine }, index);
                    }) })] }));
};
export default Location;
