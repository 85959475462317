import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './DocumentCard.module.scss'

const DocumentCard = ({ title, description, link, icon }) => {
  return (
    <div className={clsx(styles.documentCard, 'card')}>
      <div className={clsx('card-content', styles.content)}>
        <div>
          <h3
            className={styles.title}
            data-testid="document-title"
          >
            {title}
          </h3>
          <div className={styles.description}>{description}</div>
        </div>
        <span className={styles.linkAndIcon}>
          {icon && <span className={styles.icon}>{icon}</span>}
          <a
            href={link}
            className={styles.link}
          >
            {title}
          </a>
        </span>
      </div>
    </div>
  )
}

DocumentCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.object,
}

export default DocumentCard
