import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdBrightness1, MdPanoramaFishEye } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUser from '@redux/thunks/users/fetchUser';
import { COLLECTION_STATES } from 'helpers/collections';
import Debug from 'helpers/debug';
import { formatDateToDayOfWeekAndDate, formatDateToTime24 } from 'helpers/time';
import { getCurrentUserId } from 'helpers/user';
import styles from './RotaItem.module.scss';
const debug = Debug('components:store-rota');
const { UNASSIGNED } = COLLECTION_STATES;
const RotaItem = ({ nextCollection, userId, collectionState, timezone }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const userName = useAppSelector((state) => state.users.items[userId]?.name);
    useEffect(() => {
        if (collectionState !== UNASSIGNED &&
            userId !== undefined &&
            Number(getCurrentUserId()) !== userId) {
            dispatch(fetchUser(userId)).catch(debug);
        }
    }, [userId]);
    return (_jsxs("div", { className: styles.rotaItem, children: [_jsxs("div", { className: styles.bulletAndDate, children: [_jsx("span", { className: styles.icon, children: collectionState === UNASSIGNED ? (_jsx(MdPanoramaFishEye, { "data-testid": "unclaimedIcon" })) : (_jsx(MdBrightness1, { "data-testid": "claimedIcon" })) }), _jsx("div", { "data-testid": "rotaItem", children: formatDateToDayOfWeekAndDate(nextCollection, timezone) })] }), _jsx("div", { className: styles.nameAndTime, children: _jsxs("span", { children: [formatDateToTime24(nextCollection, timezone), " |", ' ', collectionState === UNASSIGNED
                            ? t('your-collections.page.show.rota.unclaimed')
                            : userName] }) })] }));
};
RotaItem.propTypes = {
    nextCollection: PropTypes.string.isRequired,
    userId: PropTypes.number,
    collectionState: PropTypes.string,
    timezone: PropTypes.string,
};
export default RotaItem;
