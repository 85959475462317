import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@redux/hooks';
import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation';
import ClaimCollection from 'components/AvailableCollections/ClaimCollection';
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel';
import Alert from 'components/common/Alert';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { Spacer } from 'components/layout/primitives';
import { INFO } from 'config/alert';
import { getSignedStaticMapUrl } from 'helpers/map';
import { useIsMobile } from 'hooks/application';
import { useCollection } from 'hooks/collections';
const AvailableCollectionDetailsCard = ({ collectionId }) => {
    const isMobile = useIsMobile();
    const [mapUrl, setMapUrl] = useState();
    const homeLocation = useAppSelector((state) => state.users.currentUser?.location, shallowEqual);
    const { data: collection } = useCollection({
        collectionId,
        dataToInclude: { inductions: true },
    });
    const requiredInductions = collection?.requiredInductions;
    const completedInductions = collection?.completedInductions;
    const store = collection?.store;
    const business = collection?.business;
    const storeName = store?.name;
    const businessName = business?.name;
    const storeLocation = store?.location;
    const latitude = store?.location.latitude;
    const longitude = store?.location.longitude;
    const collectionNotes = collection?.notes;
    const donationConfirmedAt = collection?.donationConfirmedAt;
    useEffect(() => {
        const getMapUrl = async () => {
            let mapSize = { width: 250, height: 250 };
            if (isMobile) {
                mapSize = { width: 334, height: 156 };
            }
            const mapUrl = await getSignedStaticMapUrl({ latitude, longitude, ...mapSize });
            setMapUrl(mapUrl);
        };
        getMapUrl();
    }, []);
    return (_jsx("div", { className: "card available-card-wrapper", children: _jsx("div", { className: "available-collections-details-card card", children: _jsxs("div", { className: "card-content", children: [_jsxs("div", { className: "collection-information", children: [_jsxs("div", { children: [collection?.derestricted === true && (_jsxs(_Fragment, { children: [_jsx(UnlimitedSlotLabel, {}), _jsx(Spacer, { x: "1.25rem" })] })), _jsx("h3", { className: "business-name", children: `${businessName} |` }), _jsx("h3", { className: "store-name", children: storeName }), _jsx(AvailableCollectionInformation, { type: collection?.type, frequency: collection?.frequency, nextCollection: collection?.nextCollection, homeLocation: homeLocation, storeLocation: storeLocation, storeAddress: store?.address ?? {}, storeTimezone: store?.timezone, completedInductionsCount: completedInductions?.length, inductionsCount: requiredInductions?.length, businessName: businessName, donationConfirmedAt: donationConfirmedAt }), isMobile === false && collectionNotes !== undefined && (_jsx("div", { className: "alert-info-message", children: _jsx(Alert, { variant: INFO, children: collectionNotes }) }))] }), _jsxs("div", { children: [isMobile === true && (_jsxs(_Fragment, { children: [_jsx("div", { className: "mobile-map", children: _jsx("img", { alt: "small map of store location", src: mapUrl, "data-testid": "map-image" }) }), _jsx("div", { children: collectionNotes !== undefined && (_jsx("div", { className: "alert-info-message", children: _jsx(Alert, { variant: INFO, children: _jsx(SetInnerHtmlSafe, { children: collectionNotes }) }) })) })] })), _jsx(ClaimCollection, { collectionId: collectionId, requiredInductions: requiredInductions })] })] }), isMobile === false && (_jsx("div", { className: "right-side-map", children: _jsx("img", { alt: "larger map of store location", src: mapUrl, "data-testid": "map-image" }) }))] }) }) }));
};
export default AvailableCollectionDetailsCard;
