// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuccessModal-module--successModal--Cv5CN{background-color:#dedbec;padding:1.25rem;display:flex;flex-direction:column;height:100%;align-items:center;text-align:center}.SuccessModal-module--successModal--Cv5CN h2{color:#3b1869}.SuccessModal-module--successModal--Cv5CN p{color:#121212}@media only screen and (min-width: 777px){.SuccessModal-module--successModal--Cv5CN{height:fit-content}}.SuccessModal-module--successModal--Cv5CN img{margin:2.5rem}.SuccessModal-module--successModal--Cv5CN .SuccessModal-module--modalClose--zxTxA{display:flex;align-self:flex-end}.SuccessModal-module--successModal--Cv5CN .SuccessModal-module--modalClose--zxTxA:hover{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successModal": "SuccessModal-module--successModal--Cv5CN",
	"modalClose": "SuccessModal-module--modalClose--zxTxA"
};
export default ___CSS_LOADER_EXPORT___;
