import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { MdOutlinePlace } from 'react-icons/md'
import { useSelector } from 'react-redux'

import Card from 'components/common/Cards/Card'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Pill, { VARIANTS } from 'components/common/Pill'

import InnerCard from './InnerCard'
import styles from './UsersNearMeWidget.module.scss'

const NEARBY_USERS_RANGE_KM = 2
const { PINK } = VARIANTS

const UsersNearMeWidget = () => {
  const { t } = useTranslation()
  const nearbyUsersCount = useSelector((state) => state.users.currentUser?.nearbyUserCount)

  return (
    <motion.div
      layout
      transition={{
        layout: { duration: 0.2 },
      }}
    >
      <Card className={styles.card}>
        <div className={styles.headingSection}>
          <Card.Heading>{t('dashboard-scene.users-near-me.heading')}</Card.Heading>
          <Pill variant={PINK}>
            <Pill.Icon>
              <MdOutlinePlace className={styles.icon} />
            </Pill.Icon>
            <Pill.Text>
              {t('dashboard-scene.users-near-me.pillText', {
                distance: NEARBY_USERS_RANGE_KM,
              })}
            </Pill.Text>
          </Pill>
        </div>

        <Card.TextSection>{t('dashboard-scene.users-near-me.description')}</Card.TextSection>

        {nearbyUsersCount === undefined || nearbyUsersCount === 0 ? (
          <LoadingSpinner containerHeight="100px" />
        ) : (
          <InnerCard nearbyUsersCount={nearbyUsersCount} />
        )}
      </Card>
    </motion.div>
  )
}

export default UsersNearMeWidget
