import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import fetchInduction from '@redux/thunks/inductions/fetchInduction'
import AboutInduction from 'components/Inductions/AboutInduction'
import AdminContent from 'components/Inductions/AdminContent'
import InductionQuiz from 'components/Inductions/InductionQuiz'
import Button from 'components/common/Button'
import CardWithButton from 'components/common/Cards/CardWithButton'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import { FETCHING_STATUSES } from 'config/api'
import { INDUCTIONS_URL } from 'config/routes'
import { hasYoutubeVideo } from 'helpers/inductions'
import carrotWithStore from 'images/carrotWithStore.webp'
import { trackEvent } from 'services/Analytics'

const { LOADING, SUCCESS, IDLE, FAILED } = FETCHING_STATUSES

const InductionDetailsScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { inductionId } = useParams()
  const [isQuizVisible, setIsQuizVisible] = useState(false)
  const [isRetryLocked, setIsRetryLocked] = useState(true)
  const [isRetry, setIsRetry] = useState(false)
  const [isRewatch, setIsRewatch] = useState(false)
  const videoRef = useRef()

  const inductionsFetchingStatus = useSelector((state) => state.inductions.inductionsFetchingStatus)
  const inductionData = useSelector((state) => state.inductions.items[inductionId])

  const handleStartQuizClick = () => {
    setIsQuizVisible(true)
    trackEvent('INDUCTION_QUIZ_START', {
      induction_name: inductionData.title,
      refresh: inductionData.isComplete,
    })
  }

  useEffect(() => {
    dispatch(fetchInduction(inductionId))
  }, [])

  const isLoadingInduction =
    inductionsFetchingStatus === IDLE || inductionsFetchingStatus === LOADING

  const playYoutubeVideo = () => {
    videoRef.current?.playVideo()
  }

  const pauseYoutubeVideo = () => {
    videoRef.current?.pauseVideo()
  }

  return (
    <div id="induction-details-scene">
      {isLoadingInduction === true && <LoadingSpinner containerHeight="400px" />}
      {inductionsFetchingStatus === SUCCESS && inductionData?.content !== undefined && (
        <>
          <SceneHeader title={inductionData.title} />
          <AdminContent
            content={inductionData.content}
            inductionId={Number(inductionId)}
            inductionTitle={inductionData.title}
            ref={videoRef}
            setIsRetryLocked={setIsRetryLocked}
            isRewatch={isRewatch}
          />
          {isQuizVisible === true ? (
            <InductionQuiz
              inductionId={Number(inductionId)}
              isRetryLocked={isRetryLocked}
              setIsRetryLocked={setIsRetryLocked}
              pauseYoutubeVideo={pauseYoutubeVideo}
              playYoutubeVideo={playYoutubeVideo}
              isRetry={isRetry}
              setIsRetry={setIsRetry}
              setIsRewatch={setIsRewatch}
            />
          ) : hasYoutubeVideo(inductionData.content) ? (
            <AboutInduction
              handleStartQuizClick={handleStartQuizClick}
              questionCount={inductionData?.questions.length}
            />
          ) : (
            <Button
              className="induction-start-quiz-button"
              onClick={handleStartQuizClick}
            >
              {t('inductions.page.show.button-start')}
            </Button>
          )}
        </>
      )}
      {inductionsFetchingStatus === FAILED && (
        <CardWithButton
          title={t('inductions.page.show.unavailable.card.title')}
          description={t('inductions.page.show.unavailable.card.description')}
          description2={t('inductions.page.show.unavailable.card.description2')}
          buttonText={t('inductions.page.show.unavailable.card.button-text')}
          buttonLink={INDUCTIONS_URL}
          icon={carrotWithStore}
        />
      )}
    </div>
  )
}

export default memo(InductionDetailsScene)
