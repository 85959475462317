import clsx from 'clsx'
import PropTypes from 'prop-types'
import { MdOutlinePlace } from 'react-icons/md'

import styles from './UserInfo.module.scss'

const SidebarUserInfo = ({ avatar, name, location }) => {
  return (
    <div className="center-align">
      <div className={styles.userAvatar}>
        <img
          alt=""
          className="circle"
          src={avatar}
        />
      </div>
      <div className={styles.details}>
        <strong>{name}</strong>
        <span className={clsx(styles.iconAndTitle, styles.userLocation)}>
          <span className={styles.icon}>
            <MdOutlinePlace data-testid="locationIcon" />
          </span>
          <p>{location}</p>
        </span>
      </div>
    </div>
  )
}

SidebarUserInfo.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
}

export default SidebarUserInfo
