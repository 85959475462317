import PropTypes from 'prop-types'

import InductionQuizFailure from 'components/Inductions/InductionQuiz/QuizFailure'
import InductionQuizSuccess from 'components/Inductions/InductionQuiz/QuizSuccess'
import LoadingSpinner from 'components/common/LoadingSpinner'

const QuizResults = ({
  questions,
  selectedAnswers,
  hasVideo,
  inductionTitle,
  playYoutubeVideo,
  quizCompletedSuccessfully,
  areAllAnswersCorrect,
  retryQuiz,
}) => {
  if (areAllAnswersCorrect === true && quizCompletedSuccessfully === false) {
    return <LoadingSpinner containerHeight="400px" />
  } else if (quizCompletedSuccessfully === true) {
    return (
      <InductionQuizSuccess
        questionCount={questions.length}
        inductionTitle={inductionTitle}
      />
    )
  } else {
    return (
      <InductionQuizFailure
        questions={questions}
        selectedAnswers={selectedAnswers}
        hasVideo={hasVideo}
        playYoutubeVideo={playYoutubeVideo}
        retryQuiz={retryQuiz}
      />
    )
  }
}

QuizResults.propTypes = {
  questions: PropTypes.array.isRequired,
  selectedAnswers: PropTypes.array.isRequired,
  hasVideo: PropTypes.bool.isRequired,
  inductionTitle: PropTypes.string.isRequired,
  playYoutubeVideo: PropTypes.func.isRequired,
  quizCompletedSuccessfully: PropTypes.bool.isRequired,
  areAllAnswersCorrect: PropTypes.bool.isRequired,
  retryQuiz: PropTypes.func.isRequired,
}

export default QuizResults
