import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import HowItWorksCollectionDesktopImage from 'images/HowItWorksCollectionsDesktopImage.webp'
import HowItWorksNeighbourDesktopImage from 'images/HowItWorksNeighbourDesktopImage.webp'
import HowItWorksShareDesktopImage from 'images/HowItWorksShareDesktopImage.webp'
import HowItWorksStoreDesktopImage from 'images/HowItWorksStoreDesktopImage.webp'

import styles from './HowItWorks.module.scss'

const HowItWorks = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.howItWorks}>
      <h3>{t('landing-page.how-it-works.title')}</h3>
      <div className={styles.grid}>
        <section className={styles.howItWorksSection}>
          <div className={styles.title}>
            <strong>{t('landing-page.how-it-works.claim.title')}</strong>
          </div>
          <div className={styles.stepContainer}>
            <img
              alt={t('landing-page.how-it-works.claim.alt-text')}
              width="200"
              height="402"
              src={HowItWorksCollectionDesktopImage}
              className={clsx(styles.stepImage, styles.leftImage)}
              loading="lazy"
            />
            <div>
              <p>{t('landing-page.how-it-works.claim.more-information1')}</p>
              <p>{t('landing-page.how-it-works.claim.more-information2')}</p>
              <p>{t('landing-page.how-it-works.claim.more-information3')}</p>
            </div>
          </div>
        </section>
        <section className={styles.howItWorksSection}>
          <div className={styles.title}>
            <strong>{t('landing-page.how-it-works.store.title')}</strong>
          </div>
          <div className={clsx(styles.stepContainer, styles.reversedRow)}>
            <img
              alt={t('landing-page.how-it-works.store.alt-text')}
              width="194"
              height="120"
              src={HowItWorksStoreDesktopImage}
              className={styles.stepImage}
              loading="lazy"
            />
            <p>{t('landing-page.how-it-works.store.more-information')}</p>
          </div>
        </section>
        <section className={styles.howItWorksSection}>
          <div className={styles.title}>
            <strong>{t('landing-page.how-it-works.share.title')}</strong>
          </div>
          <div className={styles.stepContainer}>
            <img
              alt={t('landing-page.how-it-works.share.alt-text')}
              width="200"
              height="403"
              src={HowItWorksShareDesktopImage}
              className={clsx(styles.stepImage, styles.leftImage)}
              loading="lazy"
            />
            <div>
              <p>{t('landing-page.how-it-works.share.more-information1')}</p>
              <p>{t('landing-page.how-it-works.share.more-information2')}</p>
            </div>
          </div>
        </section>
        <section className={styles.howItWorksSection}>
          <div className={styles.title}>
            <strong>{t('landing-page.how-it-works.neighbours.title')}</strong>
          </div>
          <div className={clsx(styles.stepContainer, styles.reversedRow)}>
            <img
              alt={t('landing-page.how-it-works.neighbours.alt-text')}
              width="192"
              height="250"
              src={HowItWorksNeighbourDesktopImage}
              className={styles.stepImage}
              loading="lazy"
            />
            <div>
              <p>{t('landing-page.how-it-works.neighbours.more-information1')}</p>
              <p>{t('landing-page.how-it-works.neighbours.more-information2')}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default HowItWorks
