import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdHelpOutline,
  MdOutlineDashboard,
  MdOutlineDescription,
  MdOutlineEventAvailable,
  MdOutlineExpandCircleDown,
  MdOutlineGroup,
  MdOutlineLiveHelp,
  MdOutlineLogout,
  MdOutlineNotificationsNone,
  MdOutlineQuiz,
  MdOutlineShoppingBag,
  MdOutlineShoppingBasket,
} from 'react-icons/md'
import { useDispatch } from 'react-redux'

import logout from '@redux/thunks/users/logout'
import SidebarLink from 'components/layout/Sidebar/Link/Link'
import SidebarUserInfo from 'components/layout/Sidebar/UserInfo/UserInfo'
import * as routes from 'config/routes'
import { getSidebarLinks } from 'config/routes'
import { useIsMobile } from 'hooks/application'
import { useAuthenticatedUser } from 'hooks/users'
import { trackEvent } from 'services/Analytics'

import styles from './Content.module.scss'

const SidebarContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [isExternalLinksOpen, setIsExternalLinksOpen] = useState(false)

  const sidebarLinks = useMemo(() => {
    return getSidebarLinks(t)
  })

  const { isFoodHero, user } = useAuthenticatedUser()
  const { city, country } = user?.address ?? {}

  const locationJoiner = city && country ? ', ' : ''
  const location = `${city || ''}${locationJoiner}${country || ''}`

  const handleLogout = () => {
    const eventName = `NAVIGATE_LOGOUT`
    trackEvent(eventName, {
      origin: 'menu',
    })

    dispatch(logout())
  }

  const toggle = () => {
    setIsExternalLinksOpen((isOpen) => !isOpen)
  }

  return (
    <div className={styles.contentContainer}>
      <div>
        <SidebarUserInfo
          userId={user?.id}
          avatar={user?.avatar?.small}
          name={user?.name?.firstName}
          location={location}
        />
        <ul>
          <SidebarLink
            type={routes.DASHBOARD}
            icon={<MdOutlineDashboard />}
          />
        </ul>

        <strong className={styles.header}>{t('layouts.sidebar.food_waste_heroes')}</strong>

        <ul>
          {isFoodHero === false && <SidebarLink type={routes.JOIN_INTRO} />}
          {isFoodHero === true && (
            <>
              <SidebarLink
                type={routes.AVAILABLE_COLLECTIONS}
                icon={<MdOutlineEventAvailable />}
              />
              <SidebarLink
                type={routes.STORES}
                icon={<MdOutlineGroup />}
              />
              <SidebarLink
                type={routes.MY_COLLECTIONS}
                icon={<MdOutlineShoppingBag />}
              />
              <SidebarLink
                type={routes.BASKETS}
                icon={<MdOutlineShoppingBasket />}
              />
              <SidebarLink
                type={routes.NOTIFICATIONS}
                icon={<MdOutlineNotificationsNone />}
              />
              <SidebarLink
                type={routes.INDUCTIONS}
                icon={<MdOutlineQuiz />}
              />
              <SidebarLink
                type={routes.DOCUMENTS}
                url={`${routes.DOCUMENTS_URL}?by_country=${country}`}
                icon={<MdOutlineDescription />}
              />
              <SidebarLink
                type={routes.FAQ}
                icon={<MdOutlineLiveHelp />}
              />
            </>
          )}
        </ul>

        {isMobile ? (
          <button
            type="button"
            className={clsx(styles.header, 'no-button-styling', styles.externalLinkWrapper)}
            onClick={toggle}
          >
            <strong>{t('layouts.sidebar.external_links')}</strong>
            {isExternalLinksOpen === true ? (
              <MdOutlineExpandCircleDown className={styles.closeIcon} />
            ) : (
              <MdOutlineExpandCircleDown className={styles.expandIcon} />
            )}
          </button>
        ) : (
          <strong className={clsx(styles.header, styles.externalLinksDesktop)}>
            {t('layouts.sidebar.external_links')}
          </strong>
        )}

        {(!isMobile || isExternalLinksOpen === true) && (
          <ul>
            <SidebarLink
              type={routes.APP_NOT_WORKING}
              target="_blank"
              icon={<MdHelpOutline />}
            />
          </ul>
        )}
      </div>

      <ul className="logout">
        <li>
          <button
            className={styles.iconAndTitle}
            type="button"
            onClick={handleLogout}
            data-testid="logout-sidebar"
          >
            <MdOutlineLogout className={styles.icon} />
            <span className={styles.logout}>{sidebarLinks[routes.LOGOUT].text}</span>
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SidebarContent
