import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import LoginForm from 'components/Login/LoginForm'
import Alert from 'components/common/Alert'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { ERROR, STATUS } from 'config/alert'
import { LOGIN_TYPES } from 'config/loginTypes'
import * as routes from 'config/routes'

const { PASSWORD, MAGIC } = LOGIN_TYPES

const LoginScene = ({ loginType }) => {
  const { t } = useTranslation()
  const buttonHref = loginType === PASSWORD ? routes.LOGIN_MAGIC_URL : routes.LOGIN_URL

  const authenticationError = useSelector((state) => state.users?.authenticationError)

  const [params] = useSearchParams()
  const hasLoggedOut = params.get('logout-success') === 'true'

  return (
    <div className="login-scene row full-width">
      <div className="card col s12 l6">
        <div className="card-content">
          <div className="card-header-section">
            {hasLoggedOut === true && (
              <Alert variant={STATUS}>
                <strong>{t('common.alerts.status')} </strong>
                {t('login.logout_successful')}
              </Alert>
            )}
            <h2>{t('login.header.title')}</h2>
            <p>{t('login.header.description')}</p>
            {(authenticationError === PASSWORD || authenticationError === MAGIC) && (
              <Alert variant={ERROR}>
                {authenticationError === PASSWORD ? (
                  <SetInnerHtmlSafe>
                    {t('login-scene.attempt-login.error-with-prefix', {
                      'error-incorrect-prefix': `<b>${t(
                        'common.alerts.error-incorrect-prefix',
                      )}</b>`,
                    })}
                  </SetInnerHtmlSafe>
                ) : (
                  <SetInnerHtmlSafe>
                    {t('login-scene.attempt-magic-login.error-with-prefix', {
                      'error-prefix': `<b>${t('common.alerts.error-prefix')}</b>`,
                    })}
                  </SetInnerHtmlSafe>
                )}
              </Alert>
            )}
          </div>
          <LoginForm loginType={loginType} />
          <div className="alternative-login">
            <Link
              to={buttonHref}
              role="button"
            >
              {loginType === PASSWORD
                ? t('login.landing.password.labels.alternative')
                : t('login.landing.magic.labels.alternative')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginScene.propTypes = {
  loginType: PropTypes.string.isRequired,
}

export default LoginScene
