export const AUTHENTICATE_USER_SUCCESS = 'FWH_AUTHENTICATION_SUCCESS';
export const AUTHENTICATE_USER_ERROR = 'FWH_AUTHENTICATION_FAIL';
export const CLEAR_AUTHENTICATION_ERROR_MESSAGE = 'CLEAR_AUTHENTICATION_ERROR_MESSAGE';
export const ACCOUNT_BASKET_IDS_FETCH_SUCCESS = 'ACCOUNT_BASKET_IDS_FETCH_SUCCESS';
export const ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS';
export const BASKET_ARTICLE_FETCH_SUCCESS = 'BASKET_ARTICLE_FETCH_SUCCESS';
export const BASKET_CLOSED_SUCCESS = 'BASKET_CLOSED_SUCCESS';
export const BASKET_FETCH_SUCCESS = 'BASKET_FETCH_SUCCESS';
export const BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS = 'BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS';
export const COLLECTION_FETCH_START = 'COLLECTION_FETCH_START';
export const COLLECTION_FETCH_ERROR = 'COLLECTION_FETCH_ERROR';
export const COLLECTION_FETCH_SUCCESS = 'COLLECTION_FETCH_SUCCESS';
export const COLLECTION_INDUCTIONS_FETCH = 'COLLECTION_INDUCTIONS_FETCH';
export const COLLECTION_INDUCTIONS_FETCH_SUCCESS = 'COLLECTION_INDUCTIONS_FETCH_SUCCESS';
export const COLLECTION_VALID_END_DATES_FETCH_SUCCESS = 'COLLECTION_VALID_END_DATES_FETCH_SUCCESS';
export const COLLECTIONS_FETCH_START = 'COLLECTIONS_FETCH_START';
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS';
export const STORES_FETCH_START = 'STORES_FETCH_START';
export const STORES_FETCH_SUCCESS = 'STORES_FETCH_SUCCESS';
export const STORE_FETCH_START = 'STORE_FETCH_START';
export const STORE_FETCH_SUCCESS = 'STORE_FETCH_SUCCESS';
export const BECOME_SQUAD_CAPTAIN_SUCCESS = 'BECOME_SQUAD_CAPTAIN';
export const STEP_DOWN_AS_SQUAD_CAPTAIN_SUCCESS = 'STEP_DOWN_AS_SQUAD_CAPTAIN';
export const BECOME_SQUAD_CAPTAIN_FAIL = 'BECOME_SQUAD_CAPTAIN_FAIL';
export const STEP_DOWN_AS_SQUAD_CAPTAIN_FAIL = 'STEP_DOWN_AS_SQUAD_CAPTAIN_FAIL';
export const STORES_CLEAR_ERROR = 'STORES_CLEAR_ERROR';
export const BUSINESSES_FETCH_START = 'BUSINESSES_FETCH_START';
export const BUSINESSES_FETCH_SUCCESS = 'BUSINESSES_FETCH_SUCCESS';
export const COLLECTION_UNASSIGN_SET_OPTION = 'COLLECTION_UNASSIGN_SET_OPTION';
export const COLLECTION_UNASSIGN_SET_ACTIVE_STEP = 'COLLECTION_UNASSIGN_SET_ACTIVE_STEP';
export const COLLECTION_UNASSIGN_MODAL_CLOSE = 'COLLECTION_UNASSIGN_MODAL_CLOSE';
export const COLLECTION_UNASSIGN_START = 'COLLECTION_UNASSIGN_START';
export const COLLECTION_UNASSIGN_ERROR = 'COLLECTION_UNASSIGN_ERROR';
export const COLLECTION_UNASSIGN_SUCCESS = 'COLLECTION_UNASSIGN_SUCCESS';
export const COLLECTION_SKIP_SET_OPTION = 'COLLECTION_SKIP_SET_OPTION';
export const COLLECTION_SKIP_SET_ACTIVE_STEP = 'COLLECTION_SKIP_SET_ACTIVE_STEP';
export const COLLECTION_SKIP_MODAL_CLOSE = 'COLLECTION_SKIP_MODAL_CLOSE';
export const COLLECTION_SKIP_START = 'COLLECTION_SKIP_START';
export const COLLECTION_SKIP_ERROR = 'COLLECTION_SKIP_ERROR';
export const COLLECTION_SKIP_SUCCESS = 'COLLECTION_SKIP_SUCCESS';
export const COLLECTION_CLAIM_SUCCESS = 'COLLECTION_CLAIM_SUCCESS';
export const COLLECTION_CLAIM_FAIL = 'COLLECTION_CLAIM_FAIL';
export const COLLECTIONS_FETCHING_STATUS_SET_IDLE = 'COLLECTIONS_FETCHING_STATUS_SET_IDLE';
export const FWH_REGISTRATION_FAIL = 'FWH_REGISTRATION_FAIL';
export const FWH_REGISTRATION_SUCCESS = 'FWH_REGISTRATION_SUCCESS';
export const SET_ORIGIN_DATA = 'SET_ORIGIN_DATA';
export const USER_ACTIVITY_FETCH_SUCCESS = 'USER_ACTIVITY_FETCH_SUCCESS';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_ADDRESS_FETCH_SUCCESS = 'USER_ADDRESS_FETCH_SUCCESS';
export const USER_NEARBY_USERS_FETCH_SUCCESS = 'USER_NEARBY_USERS_FETCH_SUCCESS';
export const CURRENT_USER_FETCH_SUCCESS = 'CURRENT_USER_FETCH_SUCCESS';
export const CURRENT_USER_FETCH_ERROR = 'CURRENT_USER_FETCH_ERROR';
export const USER_DATA_HYDRATE = 'USER_DATA_HYDRATE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const COLLECTION_PAUSE_SET_OPTION = 'COLLECTION_PAUSE_SET_OPTION';
export const COLLECTION_PAUSE_SET_ACTIVE_STEP = 'COLLECTION_PAUSE_SET_ACTIVE_STEP';
export const COLLECTION_PAUSE_START = 'COLLECTION_PAUSE_START';
export const COLLECTION_PAUSE_ERROR = 'COLLECTION_PAUSE_ERROR';
export const COLLECTION_PAUSE_SUCCESS = 'COLLECTION_PAUSE';
export const COLLECTION_PAUSE_MODAL_CLOSE = 'COLLECTION_PAUSE_MODAL_CLOSE';
export const COLLECTION_SWAP_START = 'COLLECTION_SWAP_START';
export const COLLECTION_SWAP_ERROR = 'COLLECTION_SWAP_ERROR';
export const COLLECTION_SWAP_SUCCESS = 'COLLECTION_SWAP';
export const COLLECTION_SWAP_MODAL_CLOSE = 'COLLECTION_SWAP_MODAL_CLOSE';
export const COLLECTION_SWAP_SET_OPTION = 'COLLECTION_SWAP_SET_OPTION';
export const COLLECTION_SWAP_SET_ACTIVE_STEP = 'COLLECTION_SWAP_SET_ACTIVE_STEP';
export const LOAD_AVAILABLE_COLLECTIONS = 'LOAD_AVAILABLE_COLLECTIONS';
export const NO_COLLECTIONS_AVAILABLE = 'NO_COLLECTIONS_AVAILABLE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
