/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { getSidebarLinks } from 'config/routes'
import { trackEvent } from 'services/Analytics'

import styles from './Link.module.scss'

const SidebarLink = ({ icon, type, url, target = '_self', trackingEvent }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const currentPath = location.pathname

  const sidebarLinks = useMemo(() => {
    return getSidebarLinks(t)
  })
  const configUrl = sidebarLinks[type].url
  const linkUrl = url ?? configUrl
  const linkClass = currentPath?.includes(configUrl) === true ? 'active' : undefined

  const handleClick = () => {
    const eventName = trackingEvent || `NAVIGATE_${type.toUpperCase()}`
    trackEvent(eventName, {
      origin: 'menu',
    })
  }

  return (
    <a
      href={linkUrl}
      target={target}
      onClick={handleClick}
    >
      <li className={styles[linkClass]}>
        <span className={styles.iconAndTitle}>
          <span
            className={styles.icon}
            data-testid="sidebarIcon"
          >
            {icon}
          </span>
          <span
            className={styles.link}
            data-testid="sidenav-link"
          >
            {sidebarLinks[type].text}
          </span>
        </span>
      </li>
    </a>
  )
}

SidebarLink.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
  target: PropTypes.string,
  trackingEvent: PropTypes.string,
  icon: PropTypes.node,
}

export default SidebarLink
