import { useTranslation } from 'react-i18next'

import styles from './NextSteps.module.scss'

const NextSteps = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.nextSteps}>
      <div className={styles.contentWrapper}>
        <h3 className={styles.header}>{t('join-intro-page.next-steps.title')}</h3>
        <strong>{t('join-intro-page.next-steps.point-1.title')}</strong>
        <p>{t('join-intro-page.next-steps.point-1.description')}</p>
        <strong>{t('join-intro-page.next-steps.point-2.title')}</strong>
        <p>{t('join-intro-page.next-steps.point-2.description')}</p>
        <strong>{t('join-intro-page.next-steps.point-3.title')}</strong>
        <p>{t('join-intro-page.next-steps.point-3.description')}</p>
      </div>
    </section>
  )
}

export default NextSteps
