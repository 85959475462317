import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import UserAvatar from 'components/common/UserAvatar'
import { getCurrentUserId } from 'helpers/user'
import IconOlioLogo from 'images/olio-logo-header.svg'

import styles from './IdCard.module.scss'

const IdCard = () => {
  const { t } = useTranslation()
  const userName = useSelector((state) => state.users.items[getCurrentUserId()]?.name)

  return (
    <div className={clsx('card', styles.root)}>
      <div className="card-stacked">
        <div className={styles.header}>
          <img
            alt=""
            src={IconOlioLogo}
            data-testid="logo"
          />
        </div>
        <div>
          <div className={styles.content}>
            <UserAvatar
              className={styles.avatar}
              userId={Number(getCurrentUserId())}
            />
            <div className={styles.userDetails}>
              <h3>{userName}</h3>
              <strong>{t('your-collections.id-card.title')}</strong>
              <p>{t('your-collections.id-card.role')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdCard
