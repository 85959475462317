export const BASKET_CLOSE = {
    duration: 0.4,
    variants: {
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -300 },
    },
};
export const TOGGLE_SIDEBAR = {
    duration: 0.25,
    variants: {
        mobileInitial: { x: '-100%', visibility: 'hidden' },
        open: { x: 0, visibility: 'visible' },
        close: {
            x: '-100%',
            transitionEnd: {
                visibility: 'hidden',
            },
        },
    },
};
