import ReactPixel from 'react-facebook-pixel'

import Debug from 'helpers/debug'
import Env, { isProductionLike } from 'helpers/environment'
import { getCurrentUserId } from 'helpers/user'

const debug = Debug('javascript:services:analytics:facebook')
const init = () => {
  ReactPixel.init(
    Env.PUBLIC_FACEBOOK_PIXEL_ID,
    {
      external_id: getCurrentUserId() || null,
    },
    {
      debug: isProductionLike === false,
    },
  )
}

init()

const track = (eventName, eventPayload) => {
  debug(`Track: ${eventName}, ${JSON.stringify(eventPayload)}`)
  ReactPixel.trackCustom(eventName, eventPayload)
}

export default { track, init }
