import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getFieldErrorMessage } from 'helpers/errors';
const FieldError = ({ error, id }) => {
    const { t } = useTranslation();
    const getErrorMessage = () => {
        if (error.message !== '' && error.message !== undefined && error.message !== null) {
            return error.message;
        }
        return getFieldErrorMessage({ t, errorType: error.type });
    };
    return (_jsx("p", { className: "error-text", "aria-live": "polite", id: id, children: getErrorMessage() }));
};
FieldError.propTypes = {
    error: PropTypes.object,
    id: PropTypes.string,
};
export default FieldError;
