import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import fetchCurrentUser from '@redux/thunks/users/fetchCurrentUser'
import fetchUser from '@redux/thunks/users/fetchUser'
import Debug from 'helpers/debug'
import { getCurrentUserId } from 'helpers/user'

const debug = Debug('components:global-data-fetcher')

const GlobalDataFetcher = () => {
  const dispatch = useDispatch()
  const currentUserId = getCurrentUserId()

  useEffect(() => {
    dispatch(fetchCurrentUser()).catch(debug)
  }, [])

  useEffect(() => {
    if (currentUserId && currentUserId !== 'null') {
      dispatch(fetchUser(currentUserId)).catch(debug)
    }
  }, [currentUserId])

  return <Outlet />
}

export default GlobalDataFetcher
