import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import fetchCollection from '@redux/thunks/collections/fetchCollection'
import fetchCollectionInductions from '@redux/thunks/collections/fetchCollectionInductions'
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences'
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses'
import fetchStores from '@redux/thunks/stores/fetchStores'
import AvailableCollectionDetailsCard from 'components/AvailableCollections/AvailableCollectionDetailsCard'
import InductionsList from 'components/Inductions/InductionsList'
import CardWithButton from 'components/common/Cards/CardWithButton'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import { modalKeys } from 'config/modals'
import { AVAILABLE_COLLECTIONS_URL, MY_COLLECTIONS_URL } from 'config/routes'
import Debug from 'helpers/debug'
import { getCurrentUserId } from 'helpers/user'
import { useCollection } from 'hooks/collections'
import carrotWithStore from 'images/carrotWithStore.webp'

const debug = Debug('components:collections:available-collection-details')

const AvailableCollectionDetailsScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { collectionId } = useParams()
  const userId = Number(getCurrentUserId())

  const isClaimModalOpen = useSelector((state) => {
    const claimModal = state.application.openedModals.find(
      (modal) =>
        modal.key === modalKeys.CLAIM_COLLECTION_SUCCESS ||
        modal.key === modalKeys.CLAIM_COLLECTION,
    )
    if (claimModal !== undefined) return true
    return false
  }, shallowEqual)

  const {
    data: collection,
    isLoading,
    isLoadingCollections,
  } = useCollection({
    collectionId,
    dataToInclude: { inductions: true },
  })

  const storeId = collection?.storeId
  const store = collection?.store
  const business = collection?.business
  const businessId = store?.businessId
  const inductions = collection?.requiredInductions

  const sortedInductions = useMemo(() => {
    const sortedResult =
      inductions !== undefined ? [...inductions].sort((a, b) => a.isComplete - b.isComplete) : []
    return sortedResult
  }, [inductions])

  const hasInductions = inductions?.length > 0

  const isCollectionUnavailable = isLoadingCollections === false && collection === undefined

  useEffect(() => {
    dispatch(fetchCollection(collectionId)).catch(debug)
    dispatch(fetchCollectionInductions(collectionId)).catch(debug)
  }, [collectionId])

  useEffect(() => {
    if (storeId !== undefined) {
      dispatch(fetchStores([storeId])).catch(debug)
    }
  }, [storeId])

  useEffect(() => {
    if (businessId !== undefined) {
      dispatch(fetchBusinesses([businessId])).catch(debug)
    }
  }, [businessId])

  useEffect(() => {
    const isCollectionClaimedByCurrentUser = collection?.userId === userId
    if (isCollectionClaimedByCurrentUser === true && isClaimModalOpen === false) {
      const myCollectionDetailsUrl = `${MY_COLLECTIONS_URL}/${collectionId}?new=true`
      navigate(myCollectionDetailsUrl)
    }
  }, [collection?.userId, userId])

  useEffect(() => {
    dispatch(fetchUserPreferences()).catch(debug)
  }, [])

  if (isCollectionUnavailable) {
    return (
      <CardWithButton
        title={t('collection_slots.no_longer_available.title')}
        description={t('collection_slots.no_longer_available.subtitle')}
        description2={t('collection_slots.no_longer_available.description')}
        buttonText={t('collection_slots.no_longer_available.button_text')}
        buttonLink={`${window.location.origin}${AVAILABLE_COLLECTIONS_URL}`}
        icon={carrotWithStore}
      />
    )
  }

  return (
    <div id="available-collection-details-scene">
      <SceneHeader
        title={
          business?.name === undefined
            ? t('collection_slot.index.title')
            : `${business.name} | ${t('collection_slot.index.title')}`
        }
        description={t('collection_slot.index.description')}
      />
      {isLoading === true &&
      (collection === undefined ||
        collection?.business === undefined ||
        collection?.store === undefined) ? (
        <LoadingSpinner containerHeight="400px" />
      ) : (
        <>
          <AvailableCollectionDetailsCard collectionId={Number(collectionId)} />
          {hasInductions === true && (
            <div className="required-inductions">
              <h3>{t('collection-slots.show.inductions-title')}</h3>
              <InductionsList
                description={t('collection-slots.show.inductions-description')}
                inductions={sortedInductions}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default memo(AvailableCollectionDetailsScene)
