import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'components/common/Button'
import { COLLECTION_TYPES } from 'config/collections'
import { modalKeys } from 'config/modals'
import { useModal } from 'hooks/application'

import styles from './CollectionActions.module.scss'
import PauseModal from './PauseModal'
import SkipModal from './SkipModal'
import SwapModal from './SwapModal'
import UnassignModal from './UnassignModal'

const CollectionActions = ({ collectionId, collectionType, collectionEndDate }) => {
  const { t } = useTranslation()
  const { openModal: openUnassignModal } = useModal(modalKeys.UNASSIGN_COLLECTION)
  const { openModal: openSkipModal } = useModal(modalKeys.SKIP_COLLECTION)
  const { openModal: openPauseModal } = useModal(modalKeys.PAUSE_COLLECTION)
  const { openModal: openSwapModal } = useModal(modalKeys.SWAP_COLLECTION)

  const isOngoingWithoutEndDate = useMemo(() => {
    return collectionType === COLLECTION_TYPES.ONGOING && collectionEndDate === undefined
  }, [collectionType, collectionEndDate])

  return (
    <>
      <div className={styles.collectionActions}>
        {isOngoingWithoutEndDate !== true ? (
          <Button
            className={styles.adhocButton}
            secondary
            onClick={() => openUnassignModal()}
          >
            {t('your_collections.show.buttons.unassign_adhoc')}
          </Button>
        ) : (
          <Button
            secondary
            onClick={() => openUnassignModal()}
          >
            {t('your_collections.show.buttons.unassign')}
          </Button>
        )}
        {isOngoingWithoutEndDate === true && (
          <>
            <Button
              secondary
              onClick={() => openSkipModal()}
            >
              {t('your_collections.show.buttons.skip')}
            </Button>
            <Button
              secondary
              onClick={() => openPauseModal()}
            >
              {t('your_collections.show.buttons.pause')}
            </Button>
            <Button
              secondary
              onClick={() => openSwapModal()}
            >
              {t('your_collections.show.buttons.swap')}
            </Button>
          </>
        )}
      </div>
      <UnassignModal collectionId={collectionId} />
      <SkipModal collectionId={collectionId} />
      <PauseModal collectionId={collectionId} />
      <SwapModal collectionId={collectionId} />
    </>
  )
}

CollectionActions.propTypes = {
  collectionId: PropTypes.number.isRequired,
  collectionType: PropTypes.string.isRequired,
  collectionEndDate: PropTypes.string,
}

export default memo(CollectionActions)
