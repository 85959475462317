import { Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import fetchUserAddress from '@redux/thunks/users/fetchUserAddress'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import Footer from 'components/layout/Footer'
import Debug from 'helpers/debug'
import { getCurrentUserId } from 'helpers/user'

const RegistrationForm = lazy(() => import('components/Registration/RegistrationForm'))

const debug = Debug('scenes:registration')

const RegistrationScene = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const currentUserId = getCurrentUserId()
    dispatch(fetchUserAddress(currentUserId)).catch(debug)
  }, [])

  return (
    <>
      <main id="registration-scene">
        <div className="scene-container">
          <SceneHeader title={t('food_waste_heroes.join.register_as_a_food_waste_hero')} />
          <div className="form-description">
            <p>{t('food_waste_heroes.join.food_waste_hero_description')}</p>
            <p className="notice">
              {t('food_waste_heroes.join.food_waste_hero_description_notice1')}
            </p>
            <p className="notice">
              {t('food_waste_heroes.join.food_waste_hero_description_notice2')}
            </p>
          </div>
          <Suspense fallback={<LoadingSpinner containerHeight="400px" />}>
            <RegistrationForm />
          </Suspense>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default RegistrationScene
