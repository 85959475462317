import { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { selectMyCollectionsWithStoreInfo } from '@redux/selectors/collections';
import fetchMyCollections from '@redux/thunks/collections/fetchMyCollections';
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses';
import fetchStores from '@redux/thunks/stores/fetchStores';
import Debug from 'helpers/debug';
const debug = Debug('hooks:collections:useFetchMyCollectionsWithStoreInfo');
const useFetchMyCollectionsWithStoreAndBusinessInfo = () => {
    const dispatch = useAppDispatch();
    const collectionsWithStoreInfo = useAppSelector(selectMyCollectionsWithStoreInfo);
    const collectionsFetchingStatus = useAppSelector((state) => state.collections.collectionsFetchingStatus);
    const stores = useAppSelector((state) => state.stores?.items, shallowEqual);
    const storeIds = useMemo(() => Object.keys(stores), [stores]);
    const businessIds = useMemo(() => {
        const ids = [];
        Object.values(stores).forEach((store) => {
            if (store.businessId !== undefined) {
                ids.push(store.businessId);
            }
        });
        return ids;
    }, [stores]);
    const sortedCollectionsWithStoreInfo = useMemo(() => collectionsWithStoreInfo.sort((a, b) => {
        const dateA = new Date(a.nextCollection).valueOf();
        const dateB = new Date(b.nextCollection).valueOf();
        return dateA - dateB;
    }), [collectionsWithStoreInfo]);
    useEffect(() => {
        dispatch(fetchMyCollections()).catch(debug);
    }, []);
    useEffect(() => {
        if (storeIds !== undefined && storeIds.length > 0) {
            dispatch(fetchStores(storeIds)).catch(debug);
        }
    }, [storeIds.length]);
    useEffect(() => {
        if (businessIds !== undefined && businessIds.length > 0) {
            dispatch(fetchBusinesses(businessIds)).catch(debug);
        }
    }, [businessIds.length]);
    return { sortedCollectionsWithStoreInfo, fetchingStatus: collectionsFetchingStatus };
};
export default useFetchMyCollectionsWithStoreAndBusinessInfo;
