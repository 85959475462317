import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MdOutlineLock } from 'react-icons/md'
import { Link } from 'react-router-dom'

import Button from 'components/common/Button'
import PictureHeader from 'components/common/PictureHeader'
import { BECOME_A_FOOD_HERO } from 'config/pictureHeader'
import { REGISTER_URL } from 'config/routes'
import { trackEvent } from 'services/Analytics'

import styles from './BecomeAFoodWasteHero.module.scss'

const BecomeAFoodWasteHero = ({ isUserVerified }) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <PictureHeader variant={BECOME_A_FOOD_HERO} />
      <h2 className={styles.heading}>{t('join_intro_page.heading')}</h2>
      {isUserVerified === true && (
        <Link to={REGISTER_URL}>
          <Button
            onClick={() => trackEvent('FWH_NAVIGATE_COLLECTIONS_TEASER_PROCEED')}
            className={styles.button}
          >
            {t('join_intro_page.become_food_waste_volunteer')}
          </Button>
        </Link>
      )}
      {isUserVerified === false && (
        <>
          <Button
            leftContent={<MdOutlineLock data-testid="lockIcon" />}
            className={styles.button}
          >
            {t('join_intro_page.become_food_waste_volunteer')}
          </Button>
          <div className={styles.buttonHint}>
            {t('join_intro_page.unverified_info.locked_button_hint')}
          </div>
        </>
      )}
    </section>
  )
}

BecomeAFoodWasteHero.propTypes = {
  isUserVerified: PropTypes.bool,
}

export default BecomeAFoodWasteHero
