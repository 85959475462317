import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectMyCollectionsWithStoreId } from '@redux/selectors/collections'
import fetchMyCollections from '@redux/thunks/collections/fetchMyCollections'
import { COLLECTION_TYPES } from 'config/collections'
import Debug from 'helpers/debug'

const debug = Debug('components:collection-unassign:options:step:step-content')

const useFetchMyStoreCollections = ({ onlyOngoing, selectedStoreId }) => {
  const dispatch = useDispatch()

  const collectionsWithStoreId = useSelector(
    (state) => selectMyCollectionsWithStoreId(state, selectedStoreId),
    shallowEqual,
  )

  const returnedCollections = useMemo(() => {
    if (onlyOngoing === true) {
      return collectionsWithStoreId.filter(
        (collection) => collection.type === COLLECTION_TYPES.ONGOING,
      )
    }

    return collectionsWithStoreId
  }, [collectionsWithStoreId, onlyOngoing])

  const selectedStoreTimezone = useSelector(
    (state) => state.stores?.items[selectedStoreId]?.timezone,
  )

  useEffect(() => {
    if (selectedStoreId) {
      dispatch(fetchMyCollections({ storeId: selectedStoreId })).catch(debug)
    }
  }, [selectedStoreId])

  return { selectedStoreCollections: returnedCollections, selectedStoreTimezone }
}

export default useFetchMyStoreCollections
