import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MdEvent } from 'react-icons/md'

import { COLLECTION_TYPES } from 'config/collections'
import {
  calculateRelativeTimeFromNow,
  formatDateToDayAndTime24,
  formatDateToDayMonthAndTime,
} from 'helpers/time'
import { useCollection } from 'hooks/collections'

const CollectionDates = ({ collectionId }) => {
  const { t } = useTranslation()
  const { data: collection } = useCollection({ collectionId })
  const {
    frequency: collectionFrequency,
    type,
    nextCollection: nextCollectionDate,
    endDate: endCollectionDate,
    store: { timezone: storeTimezone },
  } = collection

  const shouldDisplayNextCollection =
    nextCollectionDate &&
    (!endCollectionDate || Date.parse(nextCollectionDate) <= Date.parse(endCollectionDate))

  const displayCollectionFrequency = () => {
    if (type === COLLECTION_TYPES.ADHOC || type === COLLECTION_TYPES.TEMPORARY) {
      return t(`collection-slots.claim.types.${type}`)
    }
    return t(`collection-slots.frequency.${collectionFrequency.toLowerCase()}`)
  }

  return (
    <div className="collection-dates">
      <div className="collection-date next-collection">
        <label>{t('your-collections.page.general.collection.next_time')}</label>
        {shouldDisplayNextCollection && (
          <strong className="collection-date-relative">
            {calculateRelativeTimeFromNow(nextCollectionDate)}
          </strong>
        )}
        <strong className="next-collection">
          ({formatDateToDayMonthAndTime(nextCollectionDate, storeTimezone)})
        </strong>
        <MdEvent className="calendar-icon" />
      </div>

      <div className="collection-date">
        <label>{t('your-collections.page.general.collection.current_time')}</label>
        <strong>
          {shouldDisplayNextCollection
            ? formatDateToDayAndTime24(nextCollectionDate, storeTimezone)
            : t('your-collections.page.general.collection.no_next_collection')}
        </strong>
        <strong>
          <span>(</span>
          {displayCollectionFrequency()}
          <span>)</span>
        </strong>
      </div>

      <div className="collection-date">
        <label>{t('your-collections.page.general.collection.last_time')}</label>
        {endCollectionDate && (
          <strong className="collection-date-relative">
            {calculateRelativeTimeFromNow(endCollectionDate)}
          </strong>
        )}
        <strong>
          {endCollectionDate
            ? `(${formatDateToDayMonthAndTime(endCollectionDate)})`
            : t('your-collections.page.general.collection.no_end_date')}
        </strong>
      </div>
    </div>
  )
}

CollectionDates.propTypes = {
  collectionId: PropTypes.number.isRequired,
}

export default CollectionDates
