import { useTranslation } from 'react-i18next'
import { MdLogin } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import HowItWorks from 'components/LandingPage/HowItWorks'
import MakeADifference from 'components/LandingPage/MakeADifference'
import VolunteerThoughts from 'components/LandingPage/VolunteerThoughts'
import WhatYouNeed from 'components/LandingPage/WhatYouNeed'
import CardWithButton from 'components/common/Cards/CardWithButton/CardWithButton'
import InfoBannerWithButton from 'components/common/Cards/InfoBannerWithButton'
import PictureHeader from 'components/common/PictureHeader/PictureHeader'
import VolunteeringInfoSection from 'components/common/VolunteeringInfoSection'
import Footer from 'components/layout/Footer'
import { LANDING_PAGE } from 'config/pictureHeader'
import { LOGIN_URL } from 'config/routes'
import { trackEvent } from 'services/Analytics'

import styles from './LandingPageScene.module.scss'

const LandingPageScene = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToLogin = () => {
    trackEvent('FWH_LANDING_LOG_IN')
    return navigate(LOGIN_URL)
  }

  return (
    <>
      <div
        id="landing-page-scene"
        className={styles.landingPageScene}
      >
        <div className={styles.commonPadding}>
          <InfoBannerWithButton
            title={t('landing-page.info-banner.title')}
            buttonText={t('landing-page.info-banner.button')}
            onButtonClick={navigateToLogin}
          />
          <PictureHeader
            variant={LANDING_PAGE}
            className={styles.picture}
          />
          <VolunteeringInfoSection className={styles.volunteeringInfo} />
        </div>
        <div className={styles.highlightBackground}>
          <div className={styles.commonPadding}>
            <MakeADifference />
            <HowItWorks />
          </div>
        </div>
        <div className={styles.commonPadding}>
          <VolunteerThoughts />
          <WhatYouNeed />
          <CardWithButton
            title={t('landing-page.verified.title')}
            description={t('landing-page.verified.description1')}
            description2={t('landing-page.verified.description2')}
            buttonText={t('landing-page.login-button')}
            buttonLink={LOGIN_URL}
            onClick={() => trackEvent('FWH_LANDING_LOG_IN')}
            headingIcon={<MdLogin className={styles.loginIcon} />}
            buttonSecondary
            className={styles.landingLoginCard}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default LandingPageScene
