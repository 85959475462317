import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { STORES_CLEAR_ERROR } from '@redux/actionTypes'
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections'
import toggleSquadCaptainRole from '@redux/thunks/stores/toggleSquadCaptainRole'
import fetchUser from '@redux/thunks/users/fetchUser'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import Pill from 'components/common/Pill'
import StoreInfo from 'components/common/StoreInfo'
import Rota from 'components/common/StoreRota/Rota'
import UserAvatar from 'components/common/UserAvatar'
import Debug from 'helpers/debug'
import { getReportAnIssueUrl } from 'helpers/typeform'
import { getCurrentUserId } from 'helpers/user'

import { buttonExpandingAnimationConfig, expandingAnimationConfig } from './animations'

const debug = Debug('components:store:store-card')

const StoreCard = ({ storeId, handleExpandClick, handleExpandKeyDown, isExpanded }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isToggleDisabled, setIsToggleDisabled] = useState(false)

  const {
    businessId,
    location: storeLocation,
    squadCaptain,
    name: storeName,
  } = useSelector((state) => state.stores.items[storeId] ?? {})

  const { id: squadCaptainId } = squadCaptain || {}
  const squadCaptainUser = useSelector((state) => state.users?.items[squadCaptainId], shallowEqual)
  const squadCaptainPhotoUrl = squadCaptainUser?.avatar?.small
  const squadCaptainName = squadCaptainUser?.name

  const isSquadCaptain = useMemo(() => {
    return Number(getCurrentUserId()) === Number(squadCaptainId)
  }, [squadCaptainId])

  const noSquadCaptain = useMemo(() => {
    return squadCaptainId === null || squadCaptainId === 0
  }, [squadCaptainId])

  const business = useSelector((state) => state.stores.businesses[businessId], shallowEqual)
  const businessLogo = business?.logo?.url
  const businessName = business?.name

  const displaySquadCaptainButton = useMemo(() => {
    return noSquadCaptain === true || isSquadCaptain === true
  }, [noSquadCaptain, isSquadCaptain])

  const errors = useSelector((state) => state.stores.items[storeId]?.errors)

  useEffect(() => {
    if (storeId !== undefined) {
      dispatch(fetchStoreCollections({ storeId })).catch(debug)
    }
  }, [storeId])

  useEffect(() => {
    if (squadCaptainId !== undefined && noSquadCaptain === false && isSquadCaptain === false) {
      dispatch(fetchUser(squadCaptainId)).catch(debug)
    }
  }, [squadCaptainId])

  const reportAnIssueUrl = useMemo(() => {
    return getReportAnIssueUrl({
      t,
      storeName,
      businessName,
      storeId,
      businessId,
      userId: getCurrentUserId(),
    })
  }, [storeName, businessName, storeId, businessId])

  return (
    <>
      <div className="store-card">
        <div>
          <motion.div
            layout="position"
            className="card"
          >
            <div className="card-content">
              {storeLocation !== undefined && businessLogo !== undefined && (
                <StoreInfo
                  logoUrl={businessLogo}
                  location={storeLocation}
                  storeId={storeId}
                />
              )}
              <div className="store-details">
                <div>
                  <div className="pill-with-label">
                    <label>{t('stores.store.store_name')}</label>
                    <Pill>
                      <Pill.Text>
                        <span>🛡️ {storeName}</span>
                      </Pill.Text>
                    </Pill>
                  </div>
                  <div className="pill-with-label">
                    <label>{t('stores.store.squad_captain')}</label>
                    <Pill>
                      {squadCaptainPhotoUrl && (
                        <Pill.Icon>
                          <UserAvatar
                            className="avatar"
                            imageUrl={squadCaptainPhotoUrl}
                          />
                        </Pill.Icon>
                      )}
                      <span>
                        <Pill.Text>{squadCaptainName || t('stores.store.none')}</Pill.Text>
                      </span>
                    </Pill>
                  </div>
                </div>
                <AnimatePresence mode="wait">
                  {isExpanded(storeId) === true && (
                    <>
                      <motion.div
                        className="rota-with-label"
                        {...expandingAnimationConfig}
                      >
                        <div className="rota-label">
                          {t('stores.store.squad_captain_collections')}
                        </div>
                        <Rota
                          storeId={storeId}
                          className="store-card-rota"
                        />
                        {errors !== undefined && (
                          <Alert variant="error">
                            {isSquadCaptain === false
                              ? t('stores.store.errors.become_squad_captain_error')
                              : t('stores.store.errors.unassign_squad_captain_error')}
                          </Alert>
                        )}
                        {displaySquadCaptainButton === true && (
                          <motion.div
                            className="toggle-squad-captain-role-button-wrapper"
                            {...buttonExpandingAnimationConfig}
                          >
                            <Button
                              secondary
                              disabled={isToggleDisabled}
                              onClick={async () => {
                                setIsToggleDisabled(() => true)
                                dispatch({ type: STORES_CLEAR_ERROR, payload: { storeId } })
                                await dispatch(
                                  toggleSquadCaptainRole({
                                    isSquadCaptain,
                                    storeId,
                                  }),
                                ).catch(debug)
                                setIsToggleDisabled(() => false)
                              }}
                            >
                              {noSquadCaptain === true &&
                                t('stores.store.buttons.become_squad_captain')}
                              {isSquadCaptain === true &&
                                t('stores.store.buttons.unassign_squad_captain')}
                            </Button>
                          </motion.div>
                        )}
                      </motion.div>
                    </>
                  )}
                </AnimatePresence>
              </div>
              <motion.div
                layout="position"
                initial={false}
                data-testid={`button-wrapper-${storeId}`}
                className={clsx('inline-button-wrapper', {
                  collapsed: isExpanded(storeId) === false,
                })}
              >
                <Button
                  primary
                  onClick={(event) => {
                    handleExpandClick(event, storeId)
                    dispatch({ type: STORES_CLEAR_ERROR, payload: { storeId } })
                  }}
                  onKeyDown={(event) => {
                    handleExpandKeyDown(event, storeId)
                  }}
                >
                  {isExpanded(storeId) === true
                    ? t('stores.store.buttons.close_details')
                    : t('stores.store.buttons.more_info')}
                </Button>
                <Button
                  href={reportAnIssueUrl}
                  secondary
                >
                  {t('stores.store.something_wrong_text')}
                </Button>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  )
}

StoreCard.propTypes = {
  storeId: PropTypes.number.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handleExpandKeyDown: PropTypes.func.isRequired,
  isExpanded: PropTypes.func.isRequired,
}

export default StoreCard
