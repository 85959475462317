import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './ActivityItem.module.scss'

const ActivityItem = ({ icon, name, value, description }) => {
  return (
    <article className={clsx(styles.container)}>
      <span className={styles.icon}>{icon}</span>
      <div>
        <span className={styles.name}>{name}</span>
        <span className={styles.value}>{value}</span>
        <span className={styles.description}>{description}</span>
      </div>
    </article>
  )
}

ActivityItem.propTypes = {
  icon: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
}

export default ActivityItem
