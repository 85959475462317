import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import closePauseModal from '@redux/thunks/collections/closePauseModal'
import pause from '@redux/thunks/collections/pause'
import { DEBOUNCE_TIMEOUT } from 'components/Baskets/List/Content/Article/ArticleQuantity'
import Alert from 'components/common/Alert'
import Button from 'components/common/Button'
import { ModalFooter, ModalTitle } from 'components/common/Modal/ConfirmationModal'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { COLLECTION_FREQUENCIES } from 'config/collections'
import { modalKeys, modalTrackingKeys } from 'config/modals'
import {
  COLLECTION_ACTIONS,
  LUXON_FREQUENCY_MAP,
  formatCollectionFullDate,
} from 'helpers/collections'
import Debug from 'helpers/debug'
import { translateApiErrors } from 'helpers/errors'
import { useModal, useTimeout } from 'hooks/application'

const debug = Debug('components:collections:actions:pause-confirmation')

const PauseConfirmation = ({ collectionId, storeId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isPauseButtonPressed, setIsPauseButtonPressed] = useState(false)
  const { closeModal } = useModal(modalKeys.PAUSE_COLLECTION)

  const {
    businessId,
    name: storeName,
    timezone: selectedStoreTimezone,
  } = useSelector((state) => state.stores.items[storeId])
  const { name: businessName } = useSelector((state) => state.stores.businesses[businessId])
  const { frequency, validEndDates } = useSelector((state) => state.collections.items[collectionId])
  const { selectedDate, selectedIncrement } = useSelector(
    (state) => state.collections[COLLECTION_ACTIONS.PAUSE],
  )
  const frequencyKey = LUXON_FREQUENCY_MAP[frequency]

  const dateFrom = useMemo(() => {
    if (selectedDate === 'immediate') {
      const firstValidDate = validEndDates[0]
      if (frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
        return DateTime.fromISO(firstValidDate).minus({ week: 2 })
      }
      return DateTime.fromISO(firstValidDate).minus({ [frequencyKey]: 1 })
    } else {
      return selectedDate
    }
  }, [selectedDate])

  const skippedDates = useMemo(() => {
    const result = []

    for (let i = 1; i <= selectedIncrement; i++) {
      if (frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
        const date = DateTime.fromISO(dateFrom).plus({ week: i * 2 })
        result.push(date)
      } else {
        const date = DateTime.fromISO(dateFrom).plus({ [frequencyKey]: i })
        result.push(date)
      }
    }
    return result
  }, [selectedIncrement, dateFrom, frequency])

  const startDate = useMemo(() => {
    if (frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
      return DateTime.fromISO(dateFrom).plus({ week: (selectedIncrement + 1) * 2 })
    }
    return DateTime.fromISO(dateFrom).plus({ [frequencyKey]: selectedIncrement + 1 })
  }, [selectedIncrement, dateFrom, frequency])

  const errors = useSelector((state) => state.collections.pause.errors)

  const handleConfirmClick = () => {
    setIsPauseButtonPressed(true)
    dispatch(pause({ collectionId, selectedDate, selectedIncrement })).catch(debug)
  }

  const handleCloseClick = () => {
    try {
      closeModal()
      dispatch(closePauseModal())
    } catch {
      debug()
    }
  }

  useTimeout(
    () => {
      setIsPauseButtonPressed(false)
    },
    DEBOUNCE_TIMEOUT,
    isPauseButtonPressed === true,
  )

  return (
    <>
      <div>
        <ModalTitle>
          {t('your-collections.page.show.pause-modal.confirmation-modal.title')}
        </ModalTitle>
        <div className="pause-confirmation-details">
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                'your-collections.page.show.pause-modal.confirmation-modal.pause-details_html',
                { store: `${businessName}, ${storeName}` },
              ),
            }}
          />
          <ul>
            {skippedDates.map((skippedDate, index) => (
              <li key={index}>
                <span>
                  {formatCollectionFullDate({
                    date: skippedDate,
                    timezone: selectedStoreTimezone,
                  })}
                </span>
              </li>
            ))}
          </ul>
          <p>{t('your-collections.page.show.pause-modal.confirmation-modal.start-details')}</p>
          <span>
            {formatCollectionFullDate({
              date: startDate,
              timezone: selectedStoreTimezone,
            })}
          </span>
        </div>
        {errors && (
          <Alert variant="error">
            <SetInnerHtmlSafe>{translateApiErrors({ t, errors })}</SetInnerHtmlSafe>
          </Alert>
        )}
      </div>
      <ModalFooter>
        <Button
          data-tracking={modalTrackingKeys.reject}
          onClick={handleCloseClick}
          secondary
        >
          {t('your-collections.page.show.pause-modal.confirmation-modal.buttons.cancel')}
        </Button>
        <Button
          data-tracking={modalTrackingKeys.confirm}
          onClick={handleConfirmClick}
          disabled={isPauseButtonPressed === true}
        >
          {t('your-collections.page.show.pause-modal.confirmation-modal.buttons.confirm')}
        </Button>
      </ModalFooter>
    </>
  )
}

PauseConfirmation.propTypes = {
  collectionId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
}

export default PauseConfirmation
