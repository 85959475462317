import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
export const infoItemTypes = {
    EMPTY: 'empty',
};
const InfoItem = ({ type, title, description1, description2, icon }) => {
    return (_jsx("div", { className: "info-item-container", children: _jsx("div", { className: `card ${type}-item`, children: _jsx("div", { className: "card-header", children: _jsxs("div", { className: "icon-wrapper", children: [_jsx("div", { className: "icon", children: _jsx("img", { alt: "", src: icon }) }), _jsxs("div", { className: "text-content", children: [_jsx("h3", { children: title }), _jsxs("p", { children: [_jsx("span", { children: description1 }), description2 && _jsx("span", { children: description2 })] })] })] }) }) }) }));
};
InfoItem.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description1: PropTypes.string.isRequired,
    description2: PropTypes.string,
    icon: PropTypes.string.isRequired,
};
export default InfoItem;
