import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import EmailImage from 'images/email-icon.png'

import styles from './LoginEmailRequestedScene.module.scss'

const LoginEmailRequestedScene = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={clsx(styles.card, 'card')}>
        <img
          alt=""
          src={EmailImage}
        />
        <h3>{t('login.magic_login_sent.title')}</h3>
        <p>{t('login.magic_login_sent.description')}</p>
        <p>{t('login.magic_login_sent.try-again')}</p>
      </div>
    </div>
  )
}

export default LoginEmailRequestedScene
