import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import toggleSidebar from '@redux/thunks/application/toggleSidebar'
import Footer from 'components/layout/Footer/Footer'
import Navigation from 'components/layout/Navigation'
import Sidebar from 'components/layout/Sidebar/Wrapper'
import { useIsMobile } from 'hooks/application'

const Layout = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const isSidebarOpen = useSelector((state) => state.application.isSidebarOpen)

  const onBackDropClick = () => {
    dispatch(toggleSidebar())
  }

  return (
    <>
      <div
        className={clsx('sidebar-backdrop', { overlay: isSidebarOpen === true })}
        aria-label="layouts.authenticated.sidebar_backdrop"
        onClick={onBackDropClick}
        onKeyPress={onBackDropClick}
        tabIndex="0"
        role="button"
      />
      <div
        id="authenticated-layout"
        className={clsx({
          'overflow-hidden': isSidebarOpen === true && isMobile === true,
        })}
      >
        <Navigation />
        <main className="content-wrapper">
          <Sidebar />
          <div className="scene-content-wrapper">
            <div className="scene-content">
              <Outlet />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
