import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Alert from 'components/common/Alert'
import { ModalTitle } from 'components/common/Modal/ConfirmationModal'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import { getCollectionActionDetails } from 'helpers/collections'
import { translateApiErrors } from 'helpers/errors'

import Footer from './Footer'
import Step from './Step'

const Options = ({ mode }) => {
  const { t } = useTranslation()
  const errors = useSelector((state) => state.collections[mode].errors)
  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  })
  const stepKeys = Object.keys(COLLECTION_ACTIONS_DETAILS[mode].steps)

  return (
    <>
      <div className="collection-action-options">
        <ModalTitle>{COLLECTION_ACTIONS_DETAILS[mode].optionsHeader}</ModalTitle>
        <div className="steps">
          {stepKeys.map((stepKey, index) => (
            <Step
              step={stepKey}
              key={index}
              mode={mode}
            />
          ))}
        </div>
        {errors && (
          <Alert variant="error">
            <SetInnerHtmlSafe>{translateApiErrors({ t, errors })}</SetInnerHtmlSafe>
          </Alert>
        )}
      </div>
      <Footer mode={mode} />
    </>
  )
}

Options.propTypes = {
  mode: PropTypes.string.isRequired,
}

export default Options
