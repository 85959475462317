import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MdCheckCircleOutline, MdEvent, MdHistory, MdLoop, MdOutlineRecommend, } from 'react-icons/md';
import Distance from 'components/AvailableCollections/Distance';
import Label from 'components/common/Label';
import { COLLECTION_TYPES } from 'config/collections';
import { DISTANCE_PREFERENCE_KEY } from 'config/user';
import { getCollectionFrequencyTranslation, getCollectionTypeTranslation, } from 'helpers/collections';
import { formatLongDate } from 'helpers/time';
import { useIsMobile } from 'hooks/application';
import { usePreference } from 'hooks/preferences';
import styles from './AvailableCollectionInformation.module.scss';
import InductionProgress from './InductionProgress';
import Location from './Location';
export const BUSINESS_ICELAND = 'Iceland';
export const BUSINESS_TESCO = 'Tesco';
const AvailableCollectionInformation = ({ type, homeLocation, storeLocation, frequency, nextCollection, storeAddress, storeTimezone, completedInductionsCount, inductionsCount, businessName, className, donationConfirmedAt, }) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { preference: distance } = usePreference(DISTANCE_PREFERENCE_KEY);
    const distanceUnits = distance.value;
    const getTypeIcon = () => {
        if (type === COLLECTION_TYPES.ONGOING) {
            return (_jsx(MdLoop, { className: clsx('information-icons', type), "data-testid": "loopIcon" }));
        }
        else if (type === COLLECTION_TYPES.ADHOC || type === COLLECTION_TYPES.TEMPORARY) {
            return (_jsx(MdHistory, { className: clsx('information-icons', type), "data-testid": "historyIcon" }));
        }
        return '';
    };
    const getDateText = () => {
        if (type === COLLECTION_TYPES.ONGOING) {
            return _jsx("div", { children: t('collection_slots.partials.slot.next_collection') });
        }
        else if (type === COLLECTION_TYPES.ADHOC) {
            return _jsx("div", { children: t('collection_slots.partials.slot.last_collection') });
        }
        return null;
    };
    const isCollectionTesco = businessName === BUSINESS_TESCO;
    const isIdealFirstCollection = type === COLLECTION_TYPES.ADHOC || businessName === BUSINESS_ICELAND;
    return (_jsxs("div", { children: [donationConfirmedAt !== null && (_jsx(Label, { icon: _jsx(MdCheckCircleOutline, {}), text: t('common.label.food-confirmed'), variant: "green", className: styles.foodConfirmedLabel })), _jsx("h3", { className: styles.header, children: "Collection Details" }), _jsxs("div", { className: clsx('available-collection-information', {
                    [className]: className !== undefined,
                }), children: [_jsxs("div", { className: "information-column", children: [_jsxs("div", { className: "collection-location-details", children: [_jsx(Location, { type: type, storeAddress: storeAddress }), _jsx(Distance, { baseLocation: homeLocation, destinationLocation: storeLocation, units: distanceUnits })] }), _jsxs("div", { className: clsx('collection-type-and-frequency', type), children: [_jsx("span", { children: getTypeIcon() }), _jsxs("div", { children: [_jsx("div", { className: "collection-type", children: _jsx("strong", { children: getCollectionTypeTranslation({ t, type }) }) }), type === COLLECTION_TYPES.ONGOING && (_jsx("div", { className: "frequency", children: `(${getCollectionFrequencyTranslation({ t, frequency })})` }))] })] }), isMobile === false && isIdealFirstCollection === true && isCollectionTesco !== true && (_jsx(Label, { icon: _jsx(MdOutlineRecommend, {}), text: t('common.label.ideal-for-new') }))] }), _jsxs("div", { children: [_jsxs("div", { className: "info next-collection", children: [_jsx("span", { children: _jsx(MdEvent, { className: clsx('information-icons', type), "data-testid": "eventIcon" }) }), _jsxs("div", { children: [_jsx("span", { className: "next-collection-text", children: getDateText() }), _jsx("strong", { "data-testid": "available-collection-date", children: formatLongDate(nextCollection, storeTimezone) })] })] }), inductionsCount > 0 && (_jsx(InductionProgress, { completedInductionsCount: completedInductionsCount, inductionsCount: inductionsCount, collectionType: type })), isMobile === true && isIdealFirstCollection === true && (_jsx(Label, { icon: _jsx(MdOutlineRecommend, {}), text: t('common.label.ideal-for-new') }))] })] })] }));
};
AvailableCollectionInformation.propTypes = {
    type: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    nextCollection: PropTypes.string.isRequired,
    storeAddress: PropTypes.object.isRequired,
    storeLocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
    homeLocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
    storeTimezone: PropTypes.string,
    completedInductionsCount: PropTypes.number,
    inductionsCount: PropTypes.number,
    businessName: PropTypes.string,
    className: PropTypes.string,
};
export default AvailableCollectionInformation;
